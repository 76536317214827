import React from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import './MapTab.css';
import { ConnectedIDBar } from '../containers/IDBar';
import { ConnectedLocationBar } from '../containers/LocationBar';
import { ConnectedFormatBar } from '../containers/FormatBar';
import { ConnectedControlBar } from '../containers/ControlBar';
import * as helpers from '../support/Helpers';
import '../support/CommonStyles.css';
// Connected
import { connect } from 'react-redux';

// NOTE: I've found that by not using CGView as a module, the performance doubles
// import * as d3 from 'd3'; // FOR CGView
// import CGView from '../../CGView.js';
import * as CGView from '../../CGView.js';
import { Context as SVGContext } from 'svgcanvas';
    // const cgv = new CGView.Viewer(this.divID);

class MapTab extends React.Component {

  static propTypes = {
    divID:      PropTypes.string,
    squareLock: PropTypes.bool,
    cgvHandler: PropTypes.func,
  }

  static defaultProps = {
    divID:      'cgv-container',
    squareLock: false,
  }

  constructor(props) {
    super(props);
    this.divID = props.divID;
    this.pane = React.createRef();
    this.container = React.createRef();
    this.header = React.createRef();
    this.state = {
      cgv: null,
    };
    // console.log('LOAD MAP')

    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.createCGView();
  }

  createCGView() {
    // console.log('NEW CGV')
    this.cgv = new CGView.Viewer(this.divID, {
      SVGContext,
      highlighter: {
        feature: {
          popoverContents: this.featurePopoverContents,
        }
      }
    });

    // Initial Center Line Settings
    // const centerLineSettings = {
    //   visible: helpers.localStorageGet('settings.centerLine.visible', true),
    // }
    const centerLineSettings = helpers.localStorageGet('settings.centerLine', {
      visible: false,
      color: 'black',
      thickness: 1,
      dashes: [1,2],
    });
    this.cgv.centerLine.update(centerLineSettings);

    this.setState({cgv: this.cgv});
    this.props.cgvHandler(this.cgv);
  }

  onResize(width, height) {
    // return;
    console.log('RESIZE', width, height)
    // Don't resize if the container is 0x0. This indicates the tab the map is in is not visible.
    // if (width === 0 && height === 0 ) { return; }
    if (width === 0 || height === 0 ) { return; }
    // if (width === undefined && height === undefined ) { return; }
    width = width || (this.pane.current && this.pane.current.offsetWidth);
    height = height || (this.pane.current && this.pane.current.offsetHeight);
    const lock = this.props.squareLock;
    const mapSideBarWidth = 29;
    if (this.container.current && this.header.current) {
      const mapHeaderHeight = this.header.current.offsetHeight + 5;
      const paneWidth = width;
      const paneHeight = height;
      let mapWidth = paneWidth - mapSideBarWidth;
      let mapHeight = paneHeight - mapHeaderHeight;
      mapWidth = lock ? Math.min(mapWidth, mapHeight) : mapWidth;
      mapHeight = lock ? Math.min(mapWidth, mapHeight) : mapHeight;
      mapWidth = Math.max(mapWidth, 100);
      mapHeight = Math.max(mapHeight, 100);

      this.cgv.update({
        width: mapWidth,
        height: mapHeight,
      });
    }
  }

  featurePopoverContents = (event) => {
    const feature = event.element;
    const fullLength = feature.length !== feature.fullLength ? `(${CGView.utils.commaNumber(feature.fullLength)} bp)` : '';
    return (`
      <div style='margin: 0 5px; font-size: 14px'>
        <div class='feature-source-tag popup-feature-source'>${helpers.featureSourceIDtoSource(feature.source)}</div>
        <div>${feature.type}: ${feature.name || "<span class='popup-noname'>No Name</span>"}<div>
        <div class='track-data'>Length: ${CGView.utils.commaNumber(feature.length)} bp ${fullLength}</div>
        ${this.popupDataSection('qualifiers', feature.qualifiers)}
        ${this.popupDataSection('meta', feature.meta)}
        ${CGView.Highlighter.getTrackDiv(event)}
      </div>
    `);
  }

  popupDataSection = (title, data) => {
    if (!(data && typeof data === "object" && Object.keys(data).length > 0)) {
      return '';
    }
    return (`
      <div class='popup-data-container'>
        <div class='popup-data-badge'>${title}</div>
        <div class='popup-data-box'>
          ${CGView.Highlighter.getMetaDivs(data)}
        </div>
      </div>
    `);
  }

  render() {
    this.onResize();
    // const id = this.state.cgv && this.state.cgv.id;
    return (
      <div className='MapTab' ref={this.pane}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} refreshMode='throttle' />
        <div className='map-pane-container' ref={this.container} >
          <div className='map-header' ref={this.header} >
            <ConnectedIDBar />
            <ConnectedLocationBar />
          </div>
          <div className='map-row'>
            <div id={this.props.divID} className="CGViewPane"></div>
            <div className='button-column'>
              <ConnectedFormatBar />
              <ConnectedControlBar />
              <div className='spacer'></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapTabStateToProps = (state) => ({ squareLock: state.ui.squareLock });
const ConnectedMapTab = connect(MapTabStateToProps)(MapTab);

// export default MapTab;
export { MapTab, ConnectedMapTab };


