import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';

class DialogStart extends DefaultDialog {

  componentDidMount() {
    const seqLength = this.cgv.sequence.length;
    const minLength =   20000;
    const maxLength = 1000000;
    const contigCount = this.cgv.sequence.contigs().length;
    if (seqLength < maxLength && seqLength > minLength && contigCount == 1) {
      this.onValidate(true)
    }
  }

  renderContents() {
    const { tool } = this.props;
    const { valid } = this.state;
    return (
      <div>
        {this.renderHeader()}
        {this.renderInputsForTarget('DialogStart')}

        {valid || this.renderStop('Sequence must be a single contig and between 20 Kb and 1 Mb.')}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

