import React from 'react';
import Input from './Input';

class Inputs {

  // Tool id is for accessing DefaultSettingsManager LocalStorage
  constructor(inputs, toolID) {
    this.createInputs(inputs, toolID);
  }

  // Tool id is for accessing DefaultSettingsManager LocalStorage
  createInputs(inputs, toolID) {
    this._inputs = {};
    if (inputs) {
      for (const key of Object.keys(inputs)) {
        // this._inputs[key] = json.inputs[key];
        this._inputs[key] = new Input(key, inputs[key], toolID);
      }
    }
  }

  get(id) {
    if (!this._inputs[id]) {
      console.error(`ERROR: No Input with id '${id}'`);
    }
    return this._inputs[id]
  }

  get ids() {
    return Object.keys(this._inputs);
  }

  get list() {
    return Object.values(this._inputs);
  }

  // Returns the first appropriate input that has a useFor with the provided 'value'.
  // By appropriate, we mean the input must of the right type to match the useFor.
  // e.g. With a useFor of 'dataTrack', the input type must be selectTrack
  withUseFor(value) {
    if (value === 'dataTrack') {
      const inputs = this.forType('selectTrack');
      return inputs.filter( i => i.useFor === 'dataTrack')[0];
    } else if (value === 'dataLegend') {
      const inputs = this.forType('selectLegend');
      return inputs.filter( i => i.useFor === 'dataLegend')[0];
    } else if (value === 'dataLegendPositive') {
      const inputs = this.forType('selectLegend');
      return inputs.filter( i => i.useFor === 'dataLegendPositive')[0];
    } else if (value === 'dataLegendNegative') {
      const inputs = this.forType('selectLegend');
      return inputs.filter( i => i.useFor === 'dataLegendNegative')[0];
    }
  }

  // Returns an array of inputs that have the matching type
  forType(type) {
    return this.list.filter( i => i.type === type );
  }

  defaultOptions() {
    const inputs = this.list.filter( i => i.renderable() );
    const options = {};
    inputs.map( i => options[i.id] = i.default );
    return options;
  }

  render(onChange) {
    const inputs = this.list.filter( i => i.renderable() );
    const data = inputs.map( i => i.render(onChange) );
    return <div>{data}</div>
  }

  renderForTarget(target, onChange, currentValues={}) {
    const inputs = this.list.filter( i => i.renderable() && i.target === target );
    const data = inputs.map( i => i.render(onChange, {}, currentValues[i.id]) );
    return <div>{data}</div>
  }

}

export default Inputs;
