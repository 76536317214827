import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElementGroup from '../../../presenters/DataElementGroup';
import DataElement from '../../../presenters/DataElement';
import TextElement from '../../../presenters/TextElement';
import TextEditable from '../../../presenters/TextEditable';
import iconUp from '../../../images/icon-up.png';
import * as helpers from '../../../support/Helpers';
import './DialogAdd.css';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { Color as CGColor, ColorPicker } from '../../../../CGView.js';
// Couldn't install ag-charts-react so we are using the CDN (see app/views/layouts/application.html.slim)
// import { AgChartsReact } from 'ag-charts-react';

// TODO:
// - Grab current colors from the map legends if available
// - add custom option for manual editing of colors

// data for the plot will contain histogram data with identity and counts
// The first element of the data will also contain the cutoffs used to create the stack bar plot
// The cutoffs are stacked so the values are the difference between the cutoffs
// data = [
//   {identity: 0, counts: 0, cutoff_0: 0, cutoff_50: 50, cutoff_60: 10, cutoff_70: 10, ...},
//   {identity: 1, counts: 45},
//   {...}
//   {identity: 100, counts: 104},

class DialogAdd extends DefaultDialog {

  // Only allow the tool to start if there are BLAST features
  componentDidMount() {
    // Use the cutoffs from the map legends if available (otherwise use defaults)
    const blastLegends = this.getBlastLegends();
    // Get cutoffs and color scheme
    const cutoffInput = this.props.tool.inputs.get('cutoffs');
    // let cutoffsString = cutoffInput.default;
    let cutoffsString = cutoffInput.defaultValue();
    // let colorScheme = this.props.tool.inputs.get('colorScheme').default
    let colorScheme = this.props.tool.inputs.get('colorScheme').defaultValue();
    if (blastLegends.length > 0) {
      // console.log(blastLegends)
      const cutoffs = this.cutoffsFromBlastLegends(blastLegends);
      cutoffsString = cutoffs.join(',');
      const metaColorScheme = this.cgv.meta?.proksee?.blast_formatter?.colorScheme;
      const customColorScheme = this.checkForCustomColorScheme(metaColorScheme, blastLegends);
      if (customColorScheme) {
        colorScheme = 'custom';
        this.customColors = customColorScheme;
        this.onChange({attribute: 'customColors', value: customColorScheme.join('; ')})
      } else if (metaColorScheme) {
        colorScheme = metaColorScheme;
      }
    }
    // console.log(colorScheme)
    const blastFeatures = this.getBlastFeatures()
    this.onValidate(blastFeatures.length > 0);
    if (blastFeatures.length > 0) {
      // Need timeout so that dialog renders 'blast-histogram' div before add plot
      setTimeout(() => {
        this.mountHistogram(blastFeatures);
        this.onCutoffsChange(cutoffsString);
        this.onColorSchemeChange(colorScheme);
      }, 100);
    }

    this.colorPickerID = 'blast_formatter-color-picker';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.resetKey != this.state.resetKey) {
      const blastFeatures = this.getBlastFeatures()
      this.onValidate(blastFeatures.length > 0);
      if (blastFeatures.length > 0) {
        // Need timeout so that dialog renders 'blast-histogram' div before add plot
        setTimeout(() => {
          this.mountHistogram(blastFeatures);
        }, 100);
      }
    }
  }

  mountHistogram(blastFeatures) {
    const data = this.getHistogramData(blastFeatures);
    const chartOptions = this.initialChartOptions(data);
    // Add data which only holds histogram/counts data at this point
    chartOptions.data = data;
    this.updateChartDataAndSeries(chartOptions);
    // console.log(chartOptions.series)
    // console.log(chartOptions.data)
    this.chart = agCharts.AgChart.create(chartOptions);
    this.chartOptions = chartOptions
    // console.log(this.chart)
  }

  // Generate histogram data
  getHistogramData(blastFeatures) {
    // Create an object with a key for each percent identity (0-100)
    // And add up the hit counts for each percent identity
    const arr = [...Array(101).keys()].map(x => x++);
    const counts = {};
    arr.forEach( ident => (counts[ident] = 0));
    const blastFeatureLegends = [];
    const blastFeatureTracks = [];
    blastFeatures.forEach( f => {
      const ident = Math.floor(f.score * 100);
      counts[ident] += 1;
      blastFeatureLegends.push(f.legend);
      blastFeatureTracks.push(f.tracks());
    });
    this.blastFeatureLegends = [...new Set(blastFeatureLegends)];
    this.blastFeatureTracks = [...new Set(blastFeatureTracks.flat())];
    // console.log('BLAST TRACKS', this.blastFeatureTracks)
    // console.log('BLAST Legends', this.blastFeatureLegends)
    // console.log(counts)
    // Data will hold the count/frequency data for each perecent identity
    const data = Object.keys(counts).map(ident => ({identity: Number(ident), counts: counts[ident]}));
    return data;
  }

  // Find the highest value in the data and add 33% to the max
  // This will be used as the y axis max and for the cutoffs
  getChartYMax(data) {
    const maxCount = Math.max(...data.map( d => d.counts ));
    return Math.floor(maxCount * 1.33);
  }

  // Often there is no hits for lower percent identities.
  // We want to set the chart navigator to zoom in on the data.
  // Here we find min value that is divisible by 10.
  // Navigator min must be between 0 and 1
  getChartNavigatorMin(data) {
    let firstIdentWithCount = 0;
    for (const d of data) {
      if (d.counts > 0) {
        firstIdentWithCount = d.identity;
        break;
      }
    };

    let navMin = 0;
    if (firstIdentWithCount % 10 === 0) {
      navMin = firstIdentWithCount;
    } else {
      navMin = firstIdentWithCount - (firstIdentWithCount % 10);
    }
    return (navMin / 100);
  }

  initialChartOptions(data) {
    // Get theme from local storage
    const storage = window.localStorage;
    const storedDarkMode = storage.getItem('theme') === 'dark';
    return {
      container: document.getElementById('blast-histogram'),
      theme: storedDarkMode ? 'ag-default-dark' : 'ag-default',
      navigator: {
        enabled: true,
        height: 16,
        margin: 15,
        min: this.getChartNavigatorMin(data),
      },
      title: {
        text: 'BLAST Hit Distribution and Cutoffs',
        fontSize: 12,
      },
      padding: {
        left: 5,
        right: 5,
        top: 5,
        bottom: 10,
      },
      legend: {
        position: 'right',
        item: {
          toggleSeriesVisible: false,
          paddingY: 4,
          paddingX: 10,
          label: {
            fontSize: 12,
          },
          marker: {
            padding: 4,
          },
        },

        listeners: {
          legendItemClick: (e) => this.handleLegendItemClicked(e),
        }
      },
      axes: [
        {
          type: 'number',
          position: 'bottom',
          tick: {
            interval: 10,
          },
          max: 101,
          nice: false,
          title: { text: 'Percent Identity', fontSize: 10 },
          label: {
            fontSize: 10,
            padding: 3,
          }
        },
        {
          type: 'number',
          max: this.getChartYMax(data),
          nice: false,
          min: 0,
          position: 'left',
          title: { text: 'BLAST Hit Counts', fontSize: 10 },
          label: {
            fontSize: 10,
            padding: 3,
            // format: '.1~s',
            format: '.1s',
          }
        },
      ],
    };

  }

  colorForScheme(scheme, index, length) {
    const fraction = (index+1) / length;
    if (scheme === 'custom') {
      if (index < this.customColors.length) {
        return this.customColors[index];
      } else {
        // If there are more cutoffs than custom colors, use d3 Greys color scheme
        return d3ScaleChromatic.interpolateGreys(fraction);
      }
    } else {
      const schemeProperty = `interpolate${scheme}`;
      return d3ScaleChromatic[schemeProperty](fraction); ;
    }
  }

  handleLegendItemClicked(e) {
    // console.log(e)
    // Print out RGB colors for CGView.js ColorPicker.js
    // const names = ['Greys', 'Blues', 'Oranges', 'Reds', 'Purples', 'Greens']
    // for (const name of names) {
    //   const testColors = [1,2,3,4,5,6,7].map( (i) => d3ScaleChromatic[`interpolate${name}`](i/7));
    //   console.log(name, testColors)
    // }

    this.setState({ displayColorPicker: true })

    const cutoff = e.itemId.replace("cutoff_", '');
    const cutoffItems = this.getCutoffItems();
    const cutoffItem = cutoffItems.find( item => item.cutoff === cutoff);
    const color = cutoffItem.color;

    const cp = new ColorPicker(this.colorPickerID);
    cp.setColor(color);
    cp.onChange = (color) => {
      const customColors = [];
      for (const item of cutoffItems) {
        if (item.cutoff === cutoff) {
          customColors.push(color.rgbaString);
        } else {
          customColors.push(item.color);
        }
      }
      this.customColors = customColors;
      this.onChange({attribute: 'customColors', value: customColors.join('; ')})
      this.onColorSchemeChange('custom');
    };
    // Center color Picker
    const rect = cp.container.parentElement.getBoundingClientRect()
    const x = (rect.width - cp.width) / 2;
    const pos = {x: x, y: cp.container.offsetTop};
    cp.setPosition(pos);

    cp.open();

    // setTimeout(() => {
    //   // Handle document clicks to close ColorPicker
    //   const clickEventListner = function(e) {
    //     if (!e.target.closest(`#${this.colorPickerID}`) && !e.target.classList.contains('theme-button')) {
    //       cp.close();
    //       console.log('CLOSE')
    //       document.removeEventListener("click", clickEventListner);
    //     }
    //   }
    //   document.addEventListener("click", clickEventListner);
    // }, 100);
  }


  // Returns an array of objects with cutoff and color properties
  getCutoffItems({cutoffs, colorScheme}={}) {
    cutoffs = cutoffs || this.state.options.cutoffs.split(',');
    colorScheme = colorScheme || this.state.options.colorScheme;
    // console.log(colorScheme)

    // We always need to show a 0 cutoff fo the plot (but we fade it slightly)
    // const noZeroCutoff = !(cutoffs.some( c => parseInt(c) === 0 ));
    // let items = noZeroCutoff ? [{cutoff: 0, color: "rgba(255,255,255, 0)", noZeroCutoff: true}] : [];
    let items = [];
    if (cutoffs.length > 0) {
      cutoffs.forEach( (cutoff, index) => {
        // const color = this.colorForScheme(colorScheme, (index+1) / cutoffs.length);
        const color = this.colorForScheme(colorScheme, index, cutoffs.length);
        items.push({cutoff: cutoff, color: color});
      });
    } else {
      // Default background color. Should only show for a second until the cutoffs are loaded
      items = [{cutoff: 0, color: "rgb(222,235,247)"}];
    }
    return items;
  }

  // Update data in options to hold cutoffs in the first element
  // Data must have already been added to options
  // Add series for cutoffs
  updateChartDataAndSeries(chartOptions, {cutoffs, colorScheme}={}) {
    const cutoffItems = this.getCutoffItems({cutoffs, colorScheme});
    const cutoffSeries = [];
    // Replace first data element with cutoffs (and add back count data)
    const oldFirstDatum = chartOptions.data[0];
    const newFirstDatum = {identity: oldFirstDatum.identity, counts: oldFirstDatum.counts};

    const chartYMax = this.getChartYMax(chartOptions.data);

    cutoffItems.forEach( (item, index) => {
      const cutoff = item.cutoff;
      const color = item.color;
      const cutoffKey = `cutoff_${cutoff}`;

      // Get the next cotoff to calculate the stacked value (difference between cutoffs)
      // The last cutoff will be 101 to be able to get features with 100% identity if there is a cutoff of 100
      const nextCutoff = cutoffItems[index + 1]?.cutoff || 101;
      const stackedValue = nextCutoff - cutoff;

      newFirstDatum[cutoffKey] = stackedValue;
      newFirstDatum['cutoffXKey'] = chartYMax;

      // Add counts for each cutoff
      const cutoffCount = chartOptions.data.slice(cutoff, nextCutoff).reduce( (sum, d) => sum + d.counts, 0 );
      const cutoffCountKey = `${cutoff}_count`;
      newFirstDatum[cutoffCountKey] = cutoffCount;

      const yName = (cutoff == 100) ? `= ${cutoff}%` : `>= ${cutoff}%`;
      const countLabel = (cutoff == 100) ? `${cutoff}%` : `${cutoff}%-${nextCutoff}%`;

      cutoffSeries.push({
        type: 'bar',
        stacked: true,
        yKey: cutoffKey,
        xKey: 'cutoffXKey',
        yName: yName,
        fill: color,
        strokeWidth: 0,
        tooltip: {
          renderer: function (params) {
            return {
              title: params.yName,
              content: `Count (${countLabel}): ${params.datum[cutoffCountKey]}`,
            }
          }
        }
      });

    });

    chartOptions.data[0] = newFirstDatum;

    // Replace plot series with new cutoff series and the histogram series
    chartOptions.series = [
      ...cutoffSeries,
      {
        type: 'column',
        xKey: 'identity',
        yKey: 'counts',
        yName: 'Counts',
        tooltip: {
          renderer: function (params) {
            return {
              title: 'BLAST Hit Count',
              content: `${params.xValue.toFixed(0)}%: ${params.yValue.toFixed(0)}`,
            };
          }
        }
      },
    ]
  }

  // Returns an array of features with a source that starts with "blast-"
  getBlastFeatures() {
    const blastFeatures = [];
    for (const feature of this.cgv.features()) {
      if (/^blast-/.test(feature.source)) {
        blastFeatures.push(feature);
      }
    }
    return blastFeatures;
  }

  // Return an array of all legends that were created previously with this tool
  getBlastLegends() {
    return this.cgv.legend.items().filter( (i) => i.meta?.proksee?.blast_formatter );
  }

  // Returns an array of cutoffs from previously generated blast legends
  cutoffsFromBlastLegends() {
    const blastLegends = this.getBlastLegends();
    const cutoffs = blastLegends.map( (l) => l.meta.proksee.blast_formatter.cutoff );
    return cutoffs;
  };

  // Note: The check will ignore opacity
  checkForCustomColorScheme(metaColorScheme, blastLegends) {
    const defaultColorScheme = this.props.tool.inputs.get('colorScheme').default
    const legendColors = blastLegends.map( l => l.color.rgbString );
    const cutoffs = this.cutoffsFromBlastLegends(blastLegends);

    if (!metaColorScheme) {
      return undefined;
    }

    if (metaColorScheme === 'custom') {
      return legendColors;
    }

    const cutoffItems = this.getCutoffItems({cutoffs, colorScheme: metaColorScheme});
    const schemeColors = cutoffItems.map( (item) => item.color );

    // console.log(legendColors)

    // If the colors are different, return the legend colors as the custom color scheme
    const schemeColorString = schemeColors.join(';').replace(/\s/g, '');
    const legendColorString = legendColors.join(';').replace(/\s/g, '');
    // console.log(schemeColorString, legendColorString)
    if (schemeColorString !== legendColorString) {
      return legendColors;
    }

  }

  updateHistogram({cutoffs, colorScheme}={}) {
    if (!this.chart) { return; }
    // console.log(cutoffs)
    this.updateChartDataAndSeries(this.chartOptions, {cutoffs, colorScheme});
    agCharts.AgChart.update(this.chart, this.chartOptions);
  }

  onCutoffsChange(cutoffsString) {
    // Removes non-numbers and non-commas from the cutoffs string
    const cutoffsStringFiltered = cutoffsString.replace(/[^0-9,]/g, '');
    let cutoffs = cutoffsStringFiltered.split(',').map( (c) => parseInt(c) );
    // Filter NaN and nubmer outside range of 0-100
    cutoffs = cutoffs.filter( c => !isNaN(c) && c >= 0 && c <= 100);
    // Remove duplicates
    cutoffs = [...new Set(cutoffs)];
    // Sorts the cutoffs
    cutoffs.sort( (a,b) => a - b );
    // Make sure the first cutoff is 0
    if (cutoffs[0] !== 0) {
      cutoffs.unshift(0);
    }
    const newCutoffString = cutoffs.join(',');
    this.onChange({attribute: 'cutoffs', value: newCutoffString});
    this.updateHistogram({cutoffs})
  }

  onColorSchemeChange(scheme) {
    this.onChange({attribute: 'colorScheme', value: scheme});
    this.updateHistogram({colorScheme: scheme})
  }

  renderAllCutoffChoices() {
    const { tool } = this.props;
    const { options } = this.state;
    const cutoffInput = tool.inputs.get('cutoffs');
    const values = cutoffInput.values;
    const choices = [];

    // Non-default cutoffs will show the default value in the help text
    const nonDefault = options.cutoffs !== tool.inputs.get('cutoffs').default
    let cutoffsHelp = cutoffInput.help;
    if (nonDefault) {
      cutoffsHelp = cutoffInput.help + ` [Default: ${cutoffInput.default}]`;
    }

    for (let i=0, len=values.length; i<len ; i+=2) {
      const firstCutoff = values[i];
      const secondCutoff = values[i+1];
      // Add help to last group
      // const help = (i+2 >= len) ? cutoffInput.help : null;
      const help = (i+2 >= len) ? cutoffsHelp : null;
      choices.push(
        <DataElementGroup key={firstCutoff} help={help}>
          {this.renderCutoffChoice(firstCutoff)}
          {secondCutoff && this.renderCutoffChoice(secondCutoff)}
        </DataElementGroup>
      )
    }
    return <div>{choices}</div>;
  }

  renderCutoffChoice(cutoffs) {
    return (
      <DataElement>
        <TextElement className='cutoffs-choice' onClick={() => this.onCutoffsChange(cutoffs)}>
          <div className='cutoffs-with-btn'>
            <div>{cutoffs}</div>
            <div><img src={iconUp} /></div>
          </div>
        </TextElement>
      </DataElement>
    );
  }

  renderColorSchemeSelect() {
    const { tool } = this.props;
    const cutoffs = this.state.options.cutoffs.split(',');
    const colorSchemeInput = tool.inputs.get('colorScheme');
    const values = {...colorSchemeInput.values};

    if (this.customColors) {
      values['custom'] = 'Custom';
    }

    const formattedValues = {};
    Object.keys(values).map( (key) => {
      const label = values[key];
      const option = <div className='color-scheme-option'><div className='color-scheme-label'>{label}</div>{cutoffs.map( (c, i) => <div className='color-scheme-swatch' key={c} style={{backgroundColor: this.colorForScheme(key, i, cutoffs.length)}} /> )}</div>;
      formattedValues[key] = option;
    });
    // console.log(formattedValues)
    return this.renderInput('colorScheme', {
      onChange: (value) => this.onColorSchemeChange(value),
      values: formattedValues,
      value: this.state.options.colorScheme,
    });
  }


  renderContents() {
    const { tool } = this.props;
    const { options } = this.state;
    const blastFeatures = this.getBlastFeatures()
    const nonDefault = options.cutoffs !== tool.inputs.get('cutoffs').default

    const toolOrMessage = (blastFeatures.length > 0) ?
        <div>
          <div className='color-picker-container' id={this.colorPickerID} /> 
          <div id='blast-histogram' />
          <div className='DataElementGroup'>
            <div className='help-text'>This plot shows the distribution of all the map BLAST hits by percent identity. The colored vertical bars (behind the histogram) show how the features will be colored based on their percent identity and the selected cutoffs (chosen below).</div>
          </div>
          <DataElementGroup>
            <DataElement label='Percent Identity Cutoffs' nonDefault={nonDefault}>
              <TextEditable className='cutoffs-choice' key={Math.random()}
                value={options.cutoffs}
                onChange={(cutoffs) => this.onCutoffsChange(cutoffs)}
                />
            </DataElement>
          </DataElementGroup>
          {this.renderAllCutoffChoices()}
          {this.renderColorSchemeSelect()}
          <DataElementGroup help={`BLAST tracks (${this.blastFeatureTracks?.length}) are sorted with the most similar tracks being towards the inside (or outside) of the map.`}>
            {this.renderInput('sortTracks')}
            {this.renderInput('sortOrderMostSimilarInside')}
          </DataElementGroup>
          {this.renderInput('hideLowerScoredEnclosedFeatures')}
          {this.renderInput('removePreviousLegends')}
          {this.renderTips()}
          {this.renderDefaultSettingsManager()}
        </div> :
        <div>
          <div className='ps-alert'>No BLAST Hits Found</div>
          <br />
          <div className='ps-alert'>Run the BLAST tool and add the results to the map before using the Formatter</div>
        </div>

    return (
      <div>
        <div className='dialog-header'>
          {`Color all (${helpers.commaNumber(blastFeatures.length)}) BLAST features based on their percent identity.`}
        </div>
        {toolOrMessage}
      </div>
    );
  }
}

export default DialogAdd;
