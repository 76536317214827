import React from 'react';
import DataElement from '../../presenters/DataElement';
import ButtonGroup from '../../presenters/ButtonGroup';
import Button from '../../presenters/Button';
import { Select, Option } from '../../presenters/Select';
import { DragTagSelect } from '../../presenters/DragTagSelect';
import Tools from '../Tools.js';
import DefaultSettingsManager from '../../presenters/DefaultSettingsManager';
import FileInput from '../../presenters/FileInput';
// import ProkseeFile from '../ProkseeFile';
import CGParse from '../../../CGParse.esm';
import * as helpers from '../../support/Helpers';

export function defaultSettingsManagerKey() {
  // We are using the component ID of 'New' for the settings manager
  return 'dsm.cgview_builder.New'
}

// Used to add the default settings to the FileInput state
// - This will use localStorage values if they exist (via defaultValue())
export function initialSettings() {
  const parseTool = Tools.get('cgview_builder');
  const inputs = parseTool.inputsForTarget('New');
  const settings = {};
  for (const input of inputs) {
    // console.log(input.id, input.default, input.defaultValue());
    settings[input.id] = input.defaultValue();
  }
  return settings;
}

// Returns false if the settings have changed from the default values
export function settingsAreNotDefault(settings = {}) {
  return DefaultSettingsManager.settingsAreNotDefault(Tools.get('cgview_builder'), 'New', settings);
}

// - settings: object of settings values from the FileInput state
// - onChange: function to update the settings in the FileInput state
export function settingsRenderer(settings = {}, onChange = () => {}, details = {}, fileText = {}, source = '') {
  // console.log('SETTINGS', settings);
  // Name Keys
  // FIXME: TAKE THIS FROM TOOL.YAML
  const defaultNameKeys = ['gene', 'locus_tag', 'product', 'note', 'db_xref', 'type'];
  let nameKeys = settings.nameKeys || defaultNameKeys;
  // Get unique set of named keys
  const nameOptions = Array.from(new Set(nameKeys.concat(defaultNameKeys)));
  // Feature Types
  const allFeatureTypes = CGParse.helpers.FEATURE_TYPES;
  // Qualifiers
  const allQualifiers = CGParse.helpers.QUALIFIERS;
  // For Default Settings Manager
  const parseTool = Tools.get('cgview_builder');
  // Parse Button
  let parseButtonEnabled = settings.settingsHaveChanged;
  // FIXME: We shouldn't be checking the DOM like this
  if ((document.querySelector('.my-file-input')?.files?.length === 0) || (source === 'ncbi')) {
    parseButtonEnabled = false;
  }
  // NonDeafult Values (May need to adjust these if the default change)
  // Feature Names
  let nameKeysNonDefault = !(helpers.arraysEqual(nameKeys, defaultNameKeys));
  // Feature Types
  let featuresNonDefault = settings.includeFeatures !== 'exclude';
  featuresNonDefault = featuresNonDefault || !helpers.arraysEqual(settings.featureTypes, ['gene', 'source', 'exon']);
  // Qualifiers
  let qualifersNonDefault = settings.includeQualifiers !== 'none';

  return (
    <div>
      <DataElement label='Feature Names (From Genbank/EMBL Feature Qualifiers)' help={`Feature names are taken from the first feature qualifier in the list that has a value. Choose/reorder feature qualifiers as needed. ${nameKeysNonDefault ? '[Default: gene, locus_tag, gene, product, note, db_xref, type]' : ''}`} nonDefault={nameKeysNonDefault}>
        <DragTagSelect mode='tags' value={nameKeys}
          placeholder='Select qualifiers to use for feature names'
          onChange={(value) => {
            onChange({attribute: 'nameKeys', value});
          }}
        >
          { nameOptions.map( s => <Option key={s}>{s}</Option> ) }
        </DragTagSelect>
      </DataElement>
      { renderInExcludeSelect(settings, onChange, {
          includeKey: 'includeFeatures',
          includeValue: settings.includeFeatures || 'exclude',
          itemsKey: 'featureTypes',
          allItems: allFeatureTypes,
          selectPlaceholder: 'Select feature types to include/exclude',
          help: `Choose which feature types to include or exclude. Keep in mind that having more features may affect performance. ${featuresNonDefault ? '[Default: Exclude: gene, source, exon]' : ''}`,
          labelName: 'Features Type Selection (from GenBank/EMBL)',
          nonDefault: featuresNonDefault,
      })}
      { renderInExcludeSelect(settings, onChange, {
          includeKey: 'includeQualifiers',
          includeValue: settings.includeQualifiers || 'exclude',
          itemsKey: 'qualifierTypes',
          allItems: allQualifiers,
          selectPlaceholder: 'Select qualifiers to include/exclude',
          help: `Choose which qualifiers to include or exclude. Keep in mind that having more qualifiers may affect performance. ${qualifersNonDefault ? '[Default: None]' : ''}`,
          labelName: 'Qualifier Selection (from GenBank/EMBL)',
          nonDefault: qualifersNonDefault,
      })}
      <div style={{display: 'flex'}}>
        { FileInput.renderReparseButton(parseButtonEnabled) }
        <DefaultSettingsManager className='dsm-cgparse' tool={parseTool} componentID='New' toolOptions={settings} resetFunc={ () => resetSettings(onChange)} showDot />
      </div>
    </div>
  );
}

// Renders the buttons: All, None, Include, Exclude
// And a Select for the items (e.g. feature types or qualifiers)
// Options provide the keys/attribute getting data from settings and changing calues with onChange
// - includeKey: key used to determine if all, none, include, or exclude (e.g. includeFeatures, includeQualifiers)
// - itemsKey: key used to get the list of items to select from (e.g. featureTypes, qualifierTypes)
// - allItems: list of all possible items to select from (e.g. [allFeatureTypes], [allQualifiers])
// - labelName: value to dislay in the label (e.g. Features, Qualifiers)
// - help: help text to display under the label
// - selectPlaceholder: placeholder text for the select
function renderInExcludeSelect(settings ={}, onChange = () => {}, options = {}) {
  const includeKey = options.includeKey || 'includeItems';
  const includeValue = settings[includeKey] || 'exclude';
  const itemsKey = options.itemsKey || 'items';
  const itemsValue = settings[itemsKey] || []
  const allItems = options.allItems || [];
  const labelName = options.labelName || 'Items';
  const nonDefault = options.nonDefault || false;
  const includeKlass = (includeValue === 'all' || includeValue === 'none') ? 'hidden' : '';
  // const includeLabel = (includeValue === 'exclude') ? <span>{labelName} to <s>Include</s> Exclude</span> : <span>{labelName} to Include</span>;
  const itemOptions = Array.from(new Set(itemsValue.concat(allItems)));
  const placeholder = options.selectPlaceholder || 'Select items to include/exclude';

  return (
    <DataElement
      label={labelName}
      help={options.help}
      nonDefault={nonDefault}
    >
      <div style={{display: 'flex'}}>
        <ButtonGroup className='inexclude-btn-group'>
          <Button width='60px'
            active={includeValue === 'all'}
            onClick={() => onChange({attribute: includeKey, value: 'all'})}
          >All</Button>
          <Button width='60px'
            active={includeValue === 'none'}
            onClick={() => onChange({attribute: includeKey, value: 'none'})}
          >None</Button>
          <Button width='60px'
            active={includeValue === 'include'}
            onClick={() => onChange({attribute: includeKey, value: 'include'})}
          >Include</Button>
          <Button width='60px'
            active={includeValue === 'exclude'}
            onClick={() => onChange({attribute: includeKey, value: 'exclude'})}
          >Exclude</Button>
        </ButtonGroup>
        <Select mode='tags' value={itemsValue}
          allowClear
          className={includeKlass}
          disabled={includeValue === 'all' || includeValue === 'none'}
          placeholder={placeholder}
          onChange={(value) => {
            onChange({attribute: itemsKey, value});
          }}
        >
          { itemOptions.map( s => <Option key={s}>{s}</Option> ) }
        </Select>
      </div>
    </DataElement>
  );
}

// Reset the settings to the default values and removes the localStorage values
function resetSettings(onChange = () => {}) {
  console.log("Resetting settings");
  // Reset LocalStorage
  localStorage.removeItem(defaultSettingsManagerKey());
  // Reset State
  const parseTool = Tools.get('cgview_builder');
  const inputs = parseTool.inputsForTarget('New');
  for (const input of inputs) {
    onChange({attribute: input.id, value: input.default});
  }
}