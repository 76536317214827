import * as d3 from 'd3';
import themes from '../constants/Themes';

export function featureSourceIDtoSource(sourceID) {
  const key = sourceID.split('-')[0].toLowerCase();
  const sourceMap = {
    'genbank': 'GenBank',
    'embl': 'EMBL',
    'orfs': 'ORFs',
    'phastest': 'PHASTEST',
    'mobile_og_db': 'mobileOG-db',
    'bakta': 'Bakta',
    'card': 'CARD',
    'crispr_cas_finder': 'CRISPRCasFinder',
    'mitos': 'MITOS',
    'plannotate': 'pLannotate',
    'prokka': 'Prokka',
    'orfs': 'ORFs',
    'blast': 'BLAST',
    'alien_hunter': 'AlienHunter',
    'phigaro': 'Phigaro',
    'virsorter': 'VirSorter',
  }
  return sourceMap[key] || key;
}

export function commaNumber(value, decimals) {
  let notation = ',';
  if (decimals) {
    notation += `.${decimals}f`;
  }
  // const format = d3.format(',');
  const format = d3.format(notation);
  return format(value);
};

/**
 * Rounds the number using toFixed
 * @param {Number} value Number to round
 * @param {Integer} places Number of decimal places to round [Default: 2]
 * @return {Number}
 */
export function round(value, places=2) {
  return Number(value.toFixed(places));
};

export function constrain(value, min, max) {
  return Math.max( Math.min(max, value), min);
};

// Check if the 2 arrays are equal
export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

// Mutates the array by moving item from oldIndex to the newIndex
export function arrayMoveMutate(array, oldIndex, newIndex) {
  array.splice( (newIndex < 0  ? (array.length + newIndex) : newIndex), 0, array.splice(oldIndex, 1)[0]);
}

// Moved item from oldIndex to the newIndex and returns a copy of the array
export function arrayMoveCopy(array, oldIndex, newIndex) {
  const newArray = array;
  arrayMoveMutate(newArray, oldIndex, newIndex);
  return newArray;
}

// Returns an array for the items object
// items must contain ids and byID properties.
export function itemsAsArray(items) {
   return items.ids.map( (id) => items.byID[id] );
}

// Returns the item at a specific index.
// items must contain ids and byID properties.
export function itemForIndex(items, index) {
   return items.byID[ items.ids[index] ];
}

// Compare 2 sets. Returns true if they contain the same members.
export function setsEqual(setA, setB) {
  if (setA.size !== setB.size) return false;
  for (let a of setA) {
    if (!setB.has(a)) return false;
  }
  return true;
}

export function union(setA, setB) {
    var _union = new Set(setA);
    for (var elem of setB) {
        _union.add(elem);
    }
    return _union;
}

export function intersection(setA, setB) {
    var _intersection = new Set();
    for (var elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem);
        }
    }
    return _intersection;
}

export function scaleValue(value, from = {min: 0, max: 1}, to = {min: 0, max: 1}) {
  return ((to.max - to.min) * (value - from.min) / (from.max - from.min)) + to.min;
};

let lastId = 0;
export function uniqueID(prefix='id') {
    lastId++;
    return `${prefix}${lastId}`;
}

// https://medium.com/better-programming/theming-react-with-css-variables-bb9efd5f1918
export function changeTheme(name) {
  const theme = themes[name];
  // Changes the theme CSS variables
  Object.keys(theme).map(key => {
    const value = theme[key];
    document.documentElement.style.setProperty(key, value);
  });
  // Change the src of themed images
  const images = document.getElementsByClassName('themed-image');
  for (const image of images) {
    image.src = image.src.replace(/\.theme-[^\.]+/, `.theme-${name}`)
  }
}

export function dateTime(dateString) {
  const dateObject = dateString ? new Date(dateString) : new Date();
  const dateFormat = d3.timeFormat("%Y-%m-%d");
  const timeFormat = d3.timeFormat("%H:%M:%S");

  const today = dateFormat(new Date());
  const yesterday = dateFormat(new Date( new Date().setDate(new Date().getDate() - 1) ));

  const date = dateFormat(dateObject);
  const time = timeFormat(dateObject);

  let friendlyDate = date;
  if (date == today) {
    friendlyDate = "Today";
  } else if (date == yesterday) {
    friendlyDate = "Yesterday";
  }

  return {
    date,
    time,
    friendlyDate,
    friendlyDateTime: `${friendlyDate}, ${time}`,
  }
}

export function friendlyDateTime(datetime) {
  return (datetime === null) ? '-' : dateTime(datetime).friendlyDateTime;
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function upcapitalizeFirstLetter(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toLowerCase() + s.slice(1);
}

export function toSentence(words=[], options={}) {
  const connector = options.connector || ', ';
  const conjunction = options.conjunction || 'and';
  if (words.length === 0) {
    return '';
  } else if (words.length <= 2) {
    return words.join(` ${conjunction} `);
  } else {
    let string = '';
    for (let i=0, len=words.length; i < len; i++) {
      const word = words[i]
      string += (i === len-1) ? `${conjunction} ${word}` : `${word}${connector}`;
    }
    return string;
  }
}

export function objectToString(obj) {
  let str = '{';
  const keys = Object.keys(obj);
  for (const key of Object.keys(obj)) {
    let value = obj[key];
    if (value && typeof value === 'object') {
      value = objectToString(value);
    }
    str += `${key}: ${value}, `;
  }
  str += '}';
  return str;
}

// Converts value (string, array, or object) to an object.
// Useful for handling Select Options.
// "String"       -> {"String": "String"}
// ["one", "two"] -> {"one": "one", "two": "two"}
export function convertToObject(value) {
  let obj = {};
  if (typeof value === 'string' || value instanceof String) {
    obj[value] = value;
  } else if (Array.isArray(value)) {
    for (const v of value) {
      obj[v] = v;
    }
  } else if (typeof value === 'object') {
    obj = value;
  } else {
    obj['Unknown'] = 'unknown';
  }
  return obj;
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 B';
    if (!bytes) return '-';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'K', 'M', 'G', 'T', 'P'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    // return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    return (bytes / Math.pow(k, i)).toFixed(dm) + ' ' + sizes[i];
}

// Returns the value from localStorage for key.
// If the value is not found, defaultValue is returned.
export function localStorageGet(key, defaultValue) {
  let value = localStorage.getItem(key);
  if (value === null) {
    return defaultValue;
  }
  return JSON.parse(value);
}

// Sets the value in localStorage for key. Value is stringified.
export function localStorageSet(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

// Return a redacted URL based on the current URL
// This code should be the same as in plausible_custom.js
// We have it here so we can use it in react components where we call plausible
export function redactedURL() {
  var url = window.location.href;
  // Replace every all-numeric sequences located between two slashes or at the end by "ID"
  var redactedURL = url.replace(/\/\d+($|\/)/g, '/ID$1')
  // Replace every UUID with PROJECT_ID
  redactedURL = redactedURL.replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, "/PROJECT_ID");
  return redactedURL;
}


// export { commaNumber, export functionrain, arrayMoveMutate, arrayMoveCopy, itemsAsArray, itemForIndex };

