import React from 'react';
import DataElement from '../presenters/DataElement';
import { Select, Option } from '../presenters/Select';
import TextInput from '../presenters/TextInput';
import NumericInput from '../presenters/NumericInput';
import Slider from '../presenters/Slider';
import Switch from '../presenters/Switch';
import FileInput from '../presenters/FileInput';
import { SequenceSelector } from '../containers/SequenceSelector';
import { ConnectedSelectTrack } from '../containers/SelectTrack';
import { ConnectedSelectLegend } from '../containers/SelectLegend';
import * as helpers from '../support/Helpers';

const renderableTypes = ['select', 'textInput', 'numberInput', 'selectTrack', 'selectLegend', 'file', 'slider', 'switch', 'sequenceSelector'];

// NOTE:
// - currentValue is ONLY used to determine if the value is different from the default
//   - This will be a little dot beside the input label and make the actual default value more prominent and clickable
class Input {

  constructor(id, data, toolID) {
    this.id = id;
    this.toolID = toolID;
    for (const key of Object.keys(data)) {
      this[key] = data[key];
    }
    // The following properties are removed to prevent any conflicts: id, type, target, defaultSettingsManager
    // const { id: myID, type, target, defaultSettingsManager, ...props } = data;
    const { id: myID, type, target, autoSave, ...props } = data;
    this.adjustAutoSave();
    // this.autoSave
    this._props = props;
  }

  // Returns the props to use for a component.
  // These props are the kwy/value pairs for an input from the tool.yaml file
  get props() {
    return this._props || {};
  }

  adjustAutoSave() {
    // For files, legends, tracks, autoSave is always false for now
    // if (['selectTrack', 'selectLegend', 'file'].includes(this.type)) {
    if (['selectTrack', 'file'].includes(this.type)) {
      this.autoSave = false;
    }
    if (this.autoSave === undefined) {
      this.autoSave = true;
    }
  }

  displayName(props={}) {
    // Name source order preference
    // - props.label: provided by custom Dialog input props
    // - this.name: comes from tool.yaml for input
    // - this.id: the key/id of this input from the tool.yaml
    return props.label || this.name || helpers.capitalize(this.id);
  }

  // Return the default value for the input from:
  // - LocalStorage or tool.yaml file
  defaultValue() {
    if (this.autoSave) {
      const storageKey = `dsm.${this.toolID}.${this.target}`;
      // console.log(storageKey)
      let savedOptions = localStorage.getItem(storageKey);
      if (savedOptions !== null) {
        savedOptions = JSON.parse(savedOptions);
        const savedDefault = savedOptions[this.id];
        if (savedDefault !== undefined) {
          // if (this.type === 'selectLegend') {
          //   console.log(savedDefault)
          //   return savedDefault?.cgvID
          // } else {
            return savedDefault;
          // }
        }
      }
    }
    if (this.type === 'selectLegend') {
      // Special Case (TODO: handle color here and in SelectLegend)
      return {cgvID: this.default, name: this.defaultNewItemName};
    // if (this.type === 'selectLegend' && this.default === 'NEW') {
      // Special Case
    //   return {cgvID: 'NEW', name: this.defaultNewItemName};
    // } else if (this.type === 'selectLegend') {
    //   return {cgvID: this.default};
    } else {
      return this.default;
    }
  }

  // ISSUES
  // - X General: select (try using display value)
  // - X Add blue dots to new gneome settings
  // - How easy is it to do the following:
  //   - CLickable default value in help to change value to default
  //     - This could be done but requires using react components in the help text
  //   - Make the dot clickable to change value to default - TOO HARD
  //     - X Also show the default value as a tooltip
  // - blast
  // - X blast formatter
  //   - Help for Percent Identity Cutoffs
  // - X GC Content/Skew: auto issues
  // - X Plots: track name
  displayHelp(props={}, nonDefault=false) {
    // Help source order preference
    // - props.help: provided by custom Dialog input props
    // - this.help: help key for input from tool.yaml
    // nonDefault: boolean to indicate if the value is different from the default
    let help = props.help || this.help;

    // Adjust Help when the value is different from the default (ie. nonDefault)
    let defaultValue = this.default;

    // Adjust Switch default value to use on/offString
    if (this.type == 'switch') {
      const onString = this.onString ? this.onString : 'Yes';
      const offString = this.offString ? this.offString : 'No';
      defaultValue = defaultValue ? onString : offString;
    }

    // Get displayed value for select
    // Only show up to the first dash "-" or ":" in the value to keep it short
    if (this.type == 'select') {
      const values = helpers.convertToObject(this.values);
      defaultValue = values[defaultValue];
      defaultValue = defaultValue?.split(/ - |: /)[0].trim();
    }

    // If nonDefault, add the default value to the help.
    if (nonDefault) {
      help = help ? `${help} ` : '';
      help = `${help}[Default: ${defaultValue}]`;
      // WORKs but really we want to pass string or react element
      // help = `<div>${help}<strong>[Default: ${defaultValue}]</strong></div>`;
    }
    return help;
  }

  // Returns true
  // - If autosave is on AND
  // - if the currentValue is different from the default value
  nonDefault(currentValue) {
    // console.log(this.id, currentValue, this.default)
    if (this.autoSave) {
      if (this.default == "Auto") {
        // Special case for Auto values
        // - this happens in the GC Content/Skew tools. We get values like "Auto (1000)"
        return !currentValue.startsWith("Auto");
      } else if (this.type === 'selectLegend') {
        if (this.default !== undefined) {
          // console.log("CURRENT VALUE", currentValue);
          // console.log("DEFAULT", this.default);
          // console.log("THIS", this);
          // console.log("DEAFULAT NEW ITEM NAME:", this.defaultNewItemName);
          if (this.default === 'NEW' && currentValue?.cgvID === 'NEW') {
            return currentValue.name !== this.defaultNewItemName;
          } else {
            return currentValue?.cgvID !== this.default;
          }
        }
      } else {
        return currentValue !== this.default;
      }
    } else {
      return false;
    }
  }

  displayAlign(props={}) {
    // Align source order preference
    // - props.align: provided by custom Dialog input props
    // - this.align: align key for input from tool.yaml
    return props.align || this.align;
  }

  renderable() {
    return renderableTypes.includes(this.type);
  }

  renderSelect(onChange, props={}, currentValue) {
    const nonDefault = this.nonDefault(currentValue);
    const propValues = props.values || this.values;
    const disabledValues = props.disabledValues || this.disabledValues || [];
    // const values = helpers.convertToObject(this.values);
    const values = helpers.convertToObject(propValues);
    // This will use the array order if this.values was an array
    // const keys = (Array.isArray(this.values)) ? this.values.map( v => v.toString()) : Object.keys(values);
    const keys = (Array.isArray(propValues)) ? propValues.map( v => v.toString()) : Object.keys(values);
    const options = keys.map( k => <Option key={k} value={k} disabled={disabledValues.includes(k)}>{values[k]}</Option> );
    // Remove disabledValues from props
    delete props.disabledValues;
      {/* <DataElement label={this.displayName()} help={this.help}> */}
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props, nonDefault)} align={this.displayAlign(props)} nonDefault={nonDefault}>
        <Select
          defaultValue={this.defaultValue()}
          {...this.props}
          onChange={ (value) => onChange({attribute: this.id, value})}
          {...props}
        >
          {options}
        </Select>
      </DataElement>
    )
  }

  renderSelectTrack(onChange, props={}) {
    return (
      <ConnectedSelectTrack
        callInitialOnChange
        title={this.displayName(props)}
        defaultItemID={this.default}
        {...this.props}
        onChange={(value) => onChange({attribute: this.id, value})}
        {...props}
      />
    )
  }

  renderSelectLegend(onChange, props={}, currentValue) {
    const nonDefault = this.nonDefault(currentValue);
    const defaultValue = this.defaultValue();
    // console.log("CURRENT VALUE", currentValue);
    // console.log("DEFAULT VALUE", defaultValue);
    // console.log("NON DEFAULT", nonDefault);
    // const defaultItemID
    return (
      <ConnectedSelectLegend
        callInitialOnChange
        nonDefault={nonDefault}
        title={this.displayName(props)}
        // defaultItemID={this.defaultValue()}
        {...this.props}
        defaultItemID={defaultValue?.cgvID}
        defaultNewItemName={defaultValue?.name}
        onChange={(value) => onChange({attribute: this.id, value})}
        {...props}
      />
    )
  }

  renderTextInput(onChange, props={}, currentValue) {
    const nonDefault = this.nonDefault(currentValue);
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props, nonDefault)} nonDefault={nonDefault}>
        <TextInput 
          callInitialOnChange
          defaultValue={this.defaultValue()}
          {...this.props}
          onChange={(value) => onChange({attribute: this.id, value})}
          {...props}
        />
      </DataElement>
    )
  }

  // REMOVE the key causes not to be there the next time the dialog is openned
  // Returns the value found in provided props or this.props for the key.
  // The key is also removed frrom both props and this.props.
  // removeKey(key, props) {
  //   let value;
  //   if (props[key] !== undefined) {
  //     value = props[key];
  //   } else if (this.props[key] !== undefined) {
  //     value = this.props[key];
  //   }
  //   delete props[key];
  //   delete this.props[key];
  //   return value;
  // }

  renderNumberInput(onChange, props={}, currentValue) {
    const nonDefault = this.nonDefault(currentValue);
    // console.log(this.props)
    // const defaultValue = this.props['default']
    //       defaultValue={defaultValue}
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props, nonDefault)} nonDefault={nonDefault}>
        <NumericInput 
          {...this.props}
          onChange={(value) => onChange({attribute: this.id, value})}
          {...props}
          defaultValue={this.defaultValue()}
        />
      </DataElement>
    )
  }

  renderSlider(onChange, props={}, currentValue) {
    const nonDefault = this.nonDefault(currentValue);
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props, nonDefault)} nonDefault={nonDefault}>
        <Slider 
          defaultValue={this.defaultValue()}
          {...this.props}
          onChange={(value) => onChange({attribute: this.id, value})}
          {...props}
        />
      </DataElement>
    )
  }

  renderSwitch(onChange, props={}, currentValue) {
    const nonDefault = this.nonDefault(currentValue);
    return (
      <DataElement label={this.displayName(props)} help={this.displayHelp(props, nonDefault)} align={this.displayAlign(props)} nonDefault={nonDefault}>
        <Switch 
          defaultValue={this.defaultValue()}
          {...this.props}
          onChange={(value) => onChange({attribute: this.id, value})}
          {...props}
        />
      </DataElement>
    )
  }

  // TODO: use a prop to determine whether to use includeText
  // TODO: help and label. How should help be handled. currrently by FileInput
  renderFile(onChange, props={}) {
    return (
      <DataElement label={this.displayName(props)}>
        <FileInput
          {...this.props}
          onChange={(pfile) => {onChange({attribute: this.id, value: pfile})}}
          {...props}
        />
      </DataElement>
    )
  }

  renderSequenceSelector(onChange, props={}) {
    //<DataElement label={this.displayName(props)} help={this.displayHelp(props)}>
    return (
      <SequenceSelector
        label={this.displayName(props)}
        help={this.displayHelp(props)}
        {...this.props}
        onChange={(value) => onChange({attribute: this.id, value})}
        {...props}
      />
    )
  }

  // render(onChange, props) {
  render(onChange, props, currentValue) {
    // console.log("CURRENT VALUE", currentValue);
    const renderMethod = `render${helpers.capitalize(this.type)}`;
    // console.log(renderMethod)
    if (typeof this[renderMethod] === 'function') {
      const display = this[renderMethod](onChange, props, currentValue);
      return <div key={this.id}>{display}</div>
    }
  }

}

export default Input;

