import React from 'react';
import DataCard from '../../../cards/DataCard';
import ExternalLink from '../../../presenters/ExternalLink';
import TextElement from '../../../presenters/TextElement';
import DataElement from '../../../presenters/DataElement';
import DataElementGroup from '../../../presenters/DataElementGroup';
import Linker from '../../../presenters/Linker';
// import ImageButton from '../../../presenters/ImageButton';
import classNames from 'classnames';
import './PhastestSummaryDataCard.css';

class PhastestSummaryDataCard extends DataCard {

  // TODO: Most common phage should be on it's own line and be expandable to show full list of phages
  // Make Data Descriptions a table with striped rows
  // Add info icon to each summary data row
  // FIX CSS: only have expanded on .fake-table-row (not on .fake-table-row-details) 
  // Change label size to 12px for details

  static DATAKEYS = {
    REGION: { label: 'Region', help: 'The number assigned to the region.' },
    REGION_LENGTH: { label: 'Region Length', help: 'The length of the sequence of that region (in bp).' },
    COMPLETENESS: { label: 'Completeness (Score)', help: 'A prediction of whether the region contains an intact or incomplete prophage based on the criteria described below.' },
    SPECIFIC_KEYWORD: { label: 'Specific Keyword', help: 'The specific phage-related keyword(s) found in protein name(s) in the region.' },
    REGION_POSITION: { label: 'Region Position', help: 'The start and end positions of the region on the bacterial chromosome.' },
    RNA_NUM: { label: 'RNA Count', help: 'The number of tRNA, rRNA and tmRNA genes present in the region.' },
    TOTAL_PROTEIN_NUM: { label: 'Total Protein Count', help: 'The number of ORFs present in the region.' },
    PHAGE_HIT_PROTEIN_NUM: { label: 'Phage Protein Count', help: 'The number of proteins in the region with matches in the phage protein database.' },
    HYPOTHETICAL_PROTEIN_NUM: { label: 'Hypothetical Protein Count', help: 'The number of hypothetical proteins in the region without a match in the database.' },
    PHAGE_HYPO_PROTEIN_PERCENTAGE: { label: 'Phage + Hypothetical Protein %', help: 'The combined percentage of phage proteins and hypothetical proteins in the region.' },
    BACTERIAL_PROTEIN_NUM: { label: '# Bacterial Proteins', help: 'The number of proteins in the region with matches in the nrfilt database.' },
    ATT_SITE_SHOWUP: { label: 'Attachment Site', help: 'The putative phage attachment site.' },
    PHAGE_SPECIES_NUM: { label: '# Phage Species', help: 'The number of different phages that have similar proteins to those in the region.' },
    MOST_COMMON_PHAGE_NAME: { label: 'Most Common Phage (count)', help: 'The phage(s) with the highest number of proteins most similar to those in the region.' },
    FIRST_MOST_COMMON_PHAGE_NUM: { label: 'First Most Common Phage #', help: 'The highest number of proteins in a phage most similar to those in the region.' },
    FIRST_MOST_COMMON_PHAGE_PERCENTAGE: { label: 'First Most Common Phage %', help: 'The percentage of proteins in # Phage Hit Proteins that are most similar to the Most Common Phage proteins.' },
    GC_PERCENTAGE: { label: 'GC %', help: 'The percentage of GC nucleotides of the region.' }
};


  // Function to extract table data (ChatGPT)
  // Returns array of phage data objects with keys as column headers (e.g. 'REGION', 'REGION_LENGTH', 'COMPLETENESS(score)', etc.)
  extractTableData(fileContent) {
    const lines = fileContent.split('\n').map(line => line.trim());
    let tableStart = lines.findIndex(line => line.startsWith('REGION'));

    if (tableStart === -1) {
        throw new Error("No table found in the file.");
    }

    // Extract column headers
    // - remove stuff in brackets (e.g. COMPLETENESS(score) -> COMPLETENESS)
    // - replace "+" with "_"
    const headers = lines[tableStart].split(/\s+/).map(header => header.replace(/\(.*\)/, '').replace('+', '_'));

    // Extract rows starting after the '-----' line
    const rows = lines.slice(tableStart + 2)
        .filter(line => line !== "")
        .map(line => line.split(/\s+/));

    // Convert rows to objects using headers as keys
    const tableData = rows.map(row => {
        const rowData = {};
        headers.forEach((header, index) => {
            rowData[header] = row[index] || null;
        });
        return rowData;
    });

    return tableData;
  }

  tableLink() {
    return <ExternalLink name='table' link='http://www.ncbi.nlm.nih.gov/genomes/GenomesGroup.cgi?taxid=10239&opt=Virus&sort=genome' size={12} />
  }

  // Help text is copied here from the file for better formatting.
  // If the file changes, this will need to be updated.
  renderMethodHelp() {
    return (
      <div className='phastest-help'>
        <div className='div-title'>Criteria for scoring prophage regions (as intact, questionable, or incomplete):</div>
        <div className='div-content'>
          <h6>Method 1:</h6>
          <ol>
            <li>If the number of certain phage organism in this {this.tableLink()} is more than
            or equal to 100% of the total number of CDS of the region, the region
            is marked with total score 150. If less than 100%, method 2 and 3 will
            be used.</li>
          </ol>
          <h6>Method 2:</h6>
          <ol>
            <li>If the number of certain phage organism in this {this.tableLink()} is more
            than 50% of the total number of CDS of the region, that phage organism
            is considered as the major potential phage for that region; the
            percentage of the total number of that phage organism in this {this.tableLink()} in
            the  total number of proteins of the region is calculated and then
            multipled by 100; the percentage of the length of that phage organism
            in this {this.tableLink()} in the length of the region is calculated and then
            multipled by 50 (phage head's encapsulation capability is
            considered).</li>
          </ol>
          <h6>Method 3:</h6>
          <ol>
            <li>If any of the specific phage-related keywords (such as 'capsid',
            'head', 'integrase', 'plate', 'tail', 'fiber', 'coat', 'transposase',
            'portal', 'terminase', 'protease' or 'lysin') are present, the score
            will be increased by 10 for each keyword found.</li>
            <li>If the size of the region is greater than 30 Kb, the score will be increased by 10.</li>
            <li>If there are at least 40 proteins in the region, the score will be increased by 10.</li>
            <li>If all of the phage-related proteins and hypothetical proteins
            constitute more than 70% of the total number of proteins in the
            region, the score will be increased by 10.</li>
          </ol>
          <h6>Final Classification</h6>
          <ul>
            <li>Compare the total score of method 2 with the total score of method
            3, the bigger one is chosen as the total score of the region.
            <ul>
              <li>If the region's total score is less than 70, it is marked as <strong>incomplete</strong></li>
              <li>If between 70 to 90, it is marked as <strong>questionable</strong></li>
              <li>If greater than 90, it is marked as <strong>intact</strong></li>
            </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  renderColumnHelp() {
    return (
      <table className='p-table full-width striped phastest-column-help'>
        <thead>
          <tr className='table-title'>
            <th colSpan={2}>Data Descriptions</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(PhastestSummaryDataCard.DATAKEYS).map(([key, data]) => (
            <tr key={key}>
              <td><strong>{data.label}</strong></td>
              <td>{data.help}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderSummary({ count }) {
    if (count) {
      return (
        <div className='phastest-help phastest-summary-found'>
            Total Phage Regions: <span className='bigger-count'>{count}</span>
        </div>
      );
    } else {
      return (
        <div className='phastest-help phastest-summary-found'>
            No Phage Regions Found
        </div>
      );
    }
  }

  renderData(summaryText) {
    const data = this.extractTableData(summaryText);

    const filePath = 'ROOT/output_phastest/summary.txt';
    const downloadPath = 'output_phastest/summary.txt';

    return (
      <div>
        <div className='report-header'>Summary</div>
        <div className='phastest-summary scroll-skinny'>
          {this.renderSummary({count: Object.keys(data).length})}
          { Object.keys(data).length && (
            <>
            <FakeTable data={data} />
            <TableKey />
            </>
          ) || ''}
          <FieldsetDivider margin='5px'>HELP</FieldsetDivider>
          {this.renderMethodHelp()}
          {this.renderColumnHelp()}
        </div>
      </div>
    );
  }

}

export default PhastestSummaryDataCard;

import iconChevron from '../../../images/icon-chevron.png';

// Based on toggle from JobSummary
// NOTE: Place the toggle in a header div with a class of 'header-toggle'
//       and add 'open' to header-toggle when the content is open
function Toggle({ hidden=false }) {
  // TODO: Options for size, color, etc.
  // hidden: makes opacity 0. Useful for when the toggle is not visible
  return (
    <div className={`toggle ${hidden && 'hidden' || ''}`}>
      <img src={iconChevron} width='15' height='9' alt='open/close' />
    </div>
  );
}

// Based on or-examples from GenomeProject.css
function FieldsetDivider({ children, margin='0' }) {
  // TODO: Options for size, color, etc.
  return (
    <fieldset style={{margin: '20px 5px 15px 6px'}} className='fieldset-divider'><legend>{children}</legend></fieldset>
  );
}

function TableKey() {
  return (
    <div className='table-key'>
      <div className='key-item'>
        <div className='key-text'>Intact</div>
        <div className='key-score'>&nbsp;(score &gt; 90)</div>
      </div>
      <div className='key-item'>
        <div className='key-text'>Questionable</div>
        <div className='key-score'>&nbsp;(score 70-90)</div>
      </div>
      <div className='key-item'>
        <div className='key-text'>Incomplete</div>
        <div className='key-score'>&nbsp;(score &lt; 70)</div>
      </div>
    </div>
  );
}

// Future Options
// Flex justtication
// default alignment
// className
// Expanding rows
function FakeTable({ data }) {

  const [expandedRows, setExpandedRows] = React.useState({});

  const toggleRow = (index) => {
      setExpandedRows(prevState => ({
          ...prevState,
          [index]: !prevState[index],
      }));
  };

  function bracketedNumberToSpan(value) {
    const parts = value.split(/(\(\d+\))/).map((part, index) => {
      if (/\(\d+\)/.test(part)) {
        // If the part matches the bracketed number, wrap it in a span
        return <span key={index} className='bracket-number'>&nbsp;{part}</span>;
      }
      return part;
    });
    return parts;
  }

  // For displaying the mostcommon phage list
  function mostCommonPhageDisplayFunc(v) {
    const match = v.match(/\(\d+\)$/);
    if (!match) return v; // Return original value if no match is found

    const bracketedNumber = match[0]; // Extract the bracketed number
    const textWithoutBracketedNumber = v.replace(/\(\d+\)$/, ''); // Remove it from the original string

    return (
      <>
        {textWithoutBracketedNumber}
        <span className="bracket-number">&nbsp;{bracketedNumber}</span>
      </>
    );
  }

  function renderDataElement(row, key, className, linkerProps) {
    const data = PhastestSummaryDataCard.DATAKEYS[key];
    let value = row[key].split(",").join(", ");

    // Add linker props if they exist
    value = linkerProps ? <Linker value={value} {...linkerProps} /> : value;

    // Replace bracketed numbers with JSX
    // value = bracketedNumberToSpan(value);

    return (
      <DataElement label={data.label || 'UNKNOWN'} helpPath={`text:${data.help}`} >
        <TextElement className={className}>{value}</TextElement>
      </DataElement>
    );
  }

  return (
    <div className='fake-table'>
      <div className='fake-table-title'>
        <Toggle hidden={true} />
        <FakeColumn width="50px">Region</FakeColumn>
        <FakeColumn width="80px">Length</FakeColumn>
        <FakeColumn width="120px" align='left'>Completeness</FakeColumn>
        <FakeColumn width="200px" grow={2} align='left'>Most Common Phage</FakeColumn>
      </div>
      <div className='div-content'>
        {data.map((row, index) => (
          <div key={index} className={`fake-table-row ${expandedRows[index] ? 'expanded' : 'collapsed'}`}>
            <div className={`fake-table-row-summary header-toggle ${expandedRows[index] ? 'open' : ''}`} onClick={() => toggleRow(index)} >
              <Toggle />
              <FakeColumn width="50px"><strong>{row.REGION}</strong></FakeColumn>
              <FakeColumn width="80px">{row.REGION_LENGTH}</FakeColumn>
              <FakeColumn width="120px" className='space-between completeness' align='left'>{bracketedNumberToSpan(row.COMPLETENESS)}</FakeColumn>
              <FakeColumn width="200px" grow={2} align='left'>{bracketedNumberToSpan(row.MOST_COMMON_PHAGE_NAME.split(",")[0])} </FakeColumn>
            </div>
            <div className={`fake-table-row-details scroll-skinny ${expandedRows[index] ? 'expanded' : 'collapsed'}`}>
              <DataElementGroup>
                {renderDataElement(row, 'REGION')}
                {renderDataElement(row, 'REGION_LENGTH')}
                {renderDataElement(row, 'REGION_POSITION')}
              </DataElementGroup>
              <DataElementGroup>
                {renderDataElement(row, 'COMPLETENESS')}
                {renderDataElement(row, 'SPECIFIC_KEYWORD')}
              </DataElementGroup>
              <DataElementGroup>
                {renderDataElement(row, 'PHAGE_SPECIES_NUM')}
                {renderDataElement(row, 'FIRST_MOST_COMMON_PHAGE_NUM')}
                {renderDataElement(row, 'FIRST_MOST_COMMON_PHAGE_PERCENTAGE')}
              </DataElementGroup>
              <DataElementGroup>
                {renderDataElement(row, 'RNA_NUM')}
                {renderDataElement(row, 'TOTAL_PROTEIN_NUM')}
                {renderDataElement(row, 'PHAGE_HIT_PROTEIN_NUM')}
              </DataElementGroup>
              <DataElementGroup>
                {renderDataElement(row, 'HYPOTHETICAL_PROTEIN_NUM')}
                {renderDataElement(row, 'PHAGE_HYPO_PROTEIN_PERCENTAGE')}
                {renderDataElement(row, 'BACTERIAL_PROTEIN_NUM')}
              </DataElementGroup>
              <DataElementGroup>
                {renderDataElement(row, 'ATT_SITE_SHOWUP')}
                {renderDataElement(row, 'GC_PERCENTAGE')}
              </DataElementGroup>
              <DataElementGroup>
                {renderDataElement(row, 'MOST_COMMON_PHAGE_NAME', 'most-common-phage-element', {
                  delimiter: ',', join: ', ', extractRegex: /^.*(NC_\d+)\(\d+\)$/,
                  url: 'https://www.ncbi.nlm.nih.gov/nuccore/{VALUE}',
                  displayFunc: mostCommonPhageDisplayFunc,
                })}
              </DataElementGroup>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function FakeColumn({ children, className, width = '50px', align = 'center', grow = 0.1 }) {
  const columnStyle = {
    flexBasis: width,
    minWidth: width,
    textAlign: align,
    flexGrow: grow,
  };

  const klass = classNames('fake-column', className);

  return <div  className={klass} style={columnStyle}>{children}</div>;
}


// COLUMN KEYS AND DESCRIPTIONS

// REGION
// REGION_LENGTH
// COMPLETENESS(score)
// SPECIFIC_KEYWORD
// REGION_POSITION
// RNA_NUM
// TOTAL_PROTEIN_NUM
// PHAGE_HIT_PROTEIN_NUM
// HYPOTHETICAL_PROTEIN_NUM
// PHAGE+HYPO_PROTEIN_PERCENTAGE
// BACTERIAL_PROTEIN_NUM
// ATT_SITE_SHOWUP
// PHAGE_SPECIES_NUM
// MOST_COMMON_PHAGE_NAME(hit_genes_count)
// FIRST_MOST_COMMON_PHAGE_NUM
// FIRST_MOST_COMMON_PHAGE_PERCENTAGE
// GC_PERCENTAGE

// Region:	The number assigned to the region.
// Region Length:	The length of the sequence of that region (in bp).
// Completeness:	A prediction of whether the region contains a intact or incomplete prophage based on the above criteria.
// Specific Keyword:	The specific phage-related keyword(s) found in protein name(s) in the region.
// Region Position:	The start and end positions of the region on the bacterial chromosome.
// # tRNA + rRNA + tmRNA:	The number of tRNA, rRNA and tmRNA genes present in the region.
// # Total Proteins:	The number of ORFs present in the region.
// # Phage Hit Proteins:	The number of proteins in the region with matches in the phage protein database.
// # Hypothetical Proteins:	The number of hypothetical proteins in the region without a match in the database.
// Phage + Hypothetical Protein %:	The combined percentage of phage proteins and hypothetical proteins in the region.
// # Bacterial Proteins:	The number of proteins in the region with matches in the nrfilt database.
// Attachment Site:	The putative phage attachment site.
// # Phage Species:	The number of different phages that have similar proteins to those in the region.
// Most Common Phage:	The phage(s) with the highest number of proteins most similar to those in the region.
// First Most Common Phage #:	The highest number of proteins in a phage most similar to those in the region.
// First Most Common Phage %:	The percentage of proteins in # Phage Hit Proteins that are most similar to the Most Common Phage proteins.
// GC %:	The percentage of GC nucleotides of the region.