import React from 'react';
import SelectWithNew from '../presenters/SelectWithNew';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DataElement from '../presenters/DataElement';
import TextElement from '../presenters/TextElement';
import TextInput from '../presenters/TextInput';
import Color from '../presenters/Color';
import { Option } from '../presenters/Select';
import * as helpers from '../support/Helpers';
import './SelectLegend.css';
// Connected
import { connect } from 'react-redux';

// SelectLegend onChange will returnCurrentItem. An object with keys
//  - cgvID:
//    - cgv-id-#: for existing objects
//    - NEW: create a new item
//    - custom addtional items
//  - all other keys are passed to object for creation or updating
//    - name:
//    - swatchColor: optional color to change 
class SelectLegend extends SelectWithNew {


  static defaultProps = {
    title: 'Legend',
    allowNewItem: true,
    allowGroupItems: false,
    defaultNewItemName: '',
    defaultNewItemColor: 'rgba(75, 150, 0, 1)',
    defaultItemID: 'NEW',
    // This items will be added to the list of options
    // - can be an array or object
    additionalItems: [],
    // Feature keys used with featureData.
    // - Each key will be added to the list of options
    // - If featureData is provided, a new Data element will appear next to the select
    // - This element will contain a horizontal scroll list of the featrueData values for the key
    // - e.g 'type' will add the list item "By Type" to the select and a list of types in the Data element
    byKeyItems: [],
    // LegendItems with the following cgvIDs will be removed from select.
    removeIDs: [],
    newItemLabel: 'Legend',
    help: 'Choose or create a legend.',
    onChange: () => {},
    // Feature data can be passed to show values (and counts) for each byItemKey
    featureData: [],
    // DONE in SelectWithNew
    // // This value will cause an initial call to onChange in the constructor
    // // This is required when the initial value will need to be sent to onChange
    // // such as in DefaultDialogs.
    // callInitialOnChange: false,
  }

  constructor(props) {
    super(props);

    const { allowNewItem, defaultItemID, defaultNewItemName, defaultNewItemColor } = props;
    const items = helpers.itemsAsArray(this.props.items);
    const filteredItems = items.filter( i=> !props.removeIDs.includes(i.cgvID));
    const selectedItemID = ( allowNewItem || (defaultItemID !== 'NEW') ) ? defaultItemID : (filteredItems[0] && filteredItems[0].cgvID);


    this.state = {
      ...this.state,
      selectedItemID,
      newItemColor: defaultNewItemColor,
    };
    // Need to call again to set the defaults
    if (props.callInitialOnChange) {
      this.returnCurrentItem();
    }
  }

  // TODO: this should go with generic react comonents
  static renderSwatch(legendItem, size=10, style={}) {
    const divStyle = {
      display: 'inline-block',
      marginRight: '5px',
      backgroundColor: legendItem.swatchColor,
      width: size,
      height: size,
      ...style,
    }
    return (
      <div className='swatch' style={divStyle}></div>
    )
  }

  // Overriding returnCurrentItem to add swatch color
  returnCurrentItem() {
    const { selectedItemID, newItemName, newItemColor } = this.state;
    this.props.onChange({cgvID: selectedItemID, name: newItemName, swatchColor: newItemColor});
  }

  // Overriding renderNewItem to add swatch
  renderNewItem() {
    const { allowNewItem, newItemLabel, defaultNewItemName, byKeyItems } = this.props;
    const { newItemName, selectedItemID, newItemColor } = this.state;
    const name = newItemName || defaultNewItemName;
    if (allowNewItem && selectedItemID === 'NEW') {
      return (
        <DataElement label={`New ${newItemLabel} Name`}>
          <div className='newItemSection'>
            <TextInput
              inputRef={ (ref) => {this.newInputRef = ref} }
              value={name}
              onChange={(value) => this.onChange({value, attribute: 'newItemName'})}
            />
            <Color
              colorString={newItemColor}
              showColorString={false}
              onChange={(value) => this.onChange({value, attribute: 'newItemColor'})}
            />
          </div>
        </DataElement>
      );
    } else {
      if (!(byKeyItems?.length && byKeyItems.includes(selectedItemID))) {
        return <DataElement />;
      }
    }
  }

   getByItemCounts(selectedItemID) {
    const { byKeyItems, featureData } = this.props;
    let counts = [];
    if (featureData?.length && byKeyItems?.length) {

      function countOccurrences(arr, key) {
        const cleanedKey = key.replace('by:', '');
        return Object.values(
          arr.reduce((acc, obj) => {
            // Split key into parts (for nested access)
            const keys = cleanedKey.split(':');
            // Traverse the object using reduce
            const name = keys.reduce((o, k) => (o && o[k] !== undefined ? o[k] : undefined), obj);
            if (name) {
              acc[name] = acc[name] || { name, count: 0 };
              acc[name].count++;
            } else {
              acc['Unknown'] = acc['Unknown'] || { name: 'Unknown', count: 0 };
              acc['Unknown'].count++;
            }
            return acc;
          }, {})
        );
      }

      counts = countOccurrences(featureData, selectedItemID);
      // console.log(counts)
    }
    return counts;
  }

  // keys are the path to access categorical data from a feature to apply legend
  // keys are in the form of 'by:tag:value' where tag is optional
  // examples:
  // - by:type                     - feature.type
  // - by:meta:category            - feature.meta.category
  // - by:qualifiers:codon_start   - feature.qualifiers.codon_start
  parseByKeyItem(key) {
    const levels = key.split(':');
    const name = levels[levels.length - 1];
    const tag = levels.length > 2 ? levels[levels.length - 2] : null;
    return { id: key, name, tag };
  }

  addByKeyItems(options) {
    const { byKeyItems } = this.props;
    // const itemsObject = helpers.convertToObject(byKeyItems);
    // for (const key of Object.keys(itemsObject)) {
    for (const key of byKeyItems) {
      const keyObject = this.parseByKeyItem(key);
      const nameTag = keyObject.tag;
      // options.unshift(<Option key={key} value={key}>By <span className='select-by-key-style'>{itemsObject[key]}</span><span>{nameTag && nameTag}</span></Option>);
      options.unshift(<Option key={key} value={key}><div className='option-by-key'>By <span className='select-by-key-style'>{keyObject.name}</span>{nameTag && <div className='by-key-tag'>{nameTag}</div>}</div></Option>);
    }
  }

  // Overriding renderGroupElement to add by key items
  renderGroupElement() {
    const { byKeyItems } = this.props;
    const { selectedItemID } = this.state;
    const keyObject = this.parseByKeyItem(selectedItemID);
    // console.log(keyObject)
    // console.log(byKeyItems)
    // console.log(selectedItemID)
    const legendItems = helpers.itemsAsArray(this.props.items);
    const legendNames = legendItems && legendItems.map( i => i.name) || [];

    if (byKeyItems?.length && byKeyItems.includes(selectedItemID)) {
      const byKeyValues = this.getByItemCounts(selectedItemID);
      // console.log(byKeyValues)
      const contents = byKeyValues.map( v => {
        const legendInUse = legendNames.includes(v.name);
        const className = classNames('by-key-value-count-pair', { 'legend-in-use': legendInUse });
        return (
          <span key={v.name} className={className}>
            <span className='by-key-value' title={`Legend Name (${legendInUse ? 'Already Exists' : 'Will be Created'})`}>{v.name}</span>
            <span className='by-key-count' title='Feature Count (to be added)'>{v.count}</span>
          </span>
        );
      });

      const label = <span className='select-by-key-style'>{keyObject.name}{keyObject.tag && <div className='by-key-tag'>{keyObject.tag}</div>}</span>;
      const labelRight = <div className='by-key-name-count' title='Legend Count'>{byKeyValues.length}</div>;
      return (
        <DataElement label={label} labelRight={labelRight}>
          <TextElement className='legend-by-key-group scroll-skinny'>{contents}</TextElement>
        </DataElement>
      );
    }
  }


  // Overriding renderOptions to add swatches
  renderOptions() {
    const { allowNewItem, newItemLabel, removeIDs } = this.props;
    const items = helpers.itemsAsArray(this.props.items);
    const swatch = SelectLegend.renderSwatch;
    const options = items.filter( i=> !removeIDs.includes(i.cgvID)).map( i => <Option key={i.cgvID} value={i.cgvID}>{swatch(i)}{i.name}</Option> );
    this.addAdditionalItems(options);
    this.addByKeyItems(options);
    if (allowNewItem) {
      options.unshift(<Option key='NEW' value='NEW'>New {newItemLabel}</Option>)
    }
    return options
  }

}

// Connected
const selectLegendMapStateToProps = (state) => ({ items: state.legendItems });
const ConnectedSelectLegend = connect(selectLegendMapStateToProps)(SelectLegend);

// export default SelectLegend;
export { SelectLegend, ConnectedSelectLegend };

