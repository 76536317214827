// This file was automatically generated by the rake task tools:update:imports.
// Date: 2025-02-14 13:55:53 -0700
// TODO: add more helpful info...

export const categoryMap = {
  "annotation": "Genome Annotation",
  "comparison": "Sequence Comparison",
  "composition": "Sequence Composition",
  "user": "User Data",
  "mge": "Mobile Genetic Elements",
  "refinement": "Map Refinements"
};

export const definitions = [
  {
    "name": "Plots",
    "id": "plots",
    "description": "Upload plot data from a text file.",
    "author": "Jason R. Grant",
    "version": "1.0.0",
    "tool_scheme": "1.0",
    "category": "user",
    "worker": "client",
    "components": {
      "DialogAdd": {
        "title": "Add Plot",
        "valid": false
      }
    },
    "inputs": {
      "file": {
        "name": "Plot File",
        "type": "file",
        "sourcesAllowed": [
          "upload"
        ],
        "help": "Upload a file describing the plot to add.",
        "uploadPlaceHolder": "Upload plot file...",
        "fileType": "csv",
        "validationRules": {
          "requiredColumns": [
            "start",
            "score"
          ],
          "allowedColumns": [
            "start",
            "stop",
            "score"
          ]
        }
      },
      "trackName": {
        "type": "textInput",
        "target": "DialogAdd",
        "name": "Track Name",
        "autoSave": false
      },
      "legendPositive": {
        "name": "Legend (Above the Baseline)",
        "type": "selectLegend",
        "autoSave": false,
        "useFor": "dataLegendPositive",
        "help": "Choose or create a new legend for the plot data above the baseline."
      },
      "legendNegative": {
        "name": "Legend (Below the Baseline)",
        "type": "selectLegend",
        "autoSave": false,
        "useFor": "dataLegendNegative",
        "additionalItems": {
          "SAME": "Same as Above Baseline"
        },
        "default": "SAME",
        "help": "Choose or create a new legend for the plot data below the baseline."
      }
    }
  },
  {
    "name": "pLannotate",
    "id": "plannotate",
    "version": "1.1.0",
    "description": "Annotate engineered plasmids with pLannotate. Only works with sequences less than 50,000 bp.",
    "author": "Jason R. Grant",
    "citation": "McGuffie MJ, Barrick JE. pLannotate: engineered plasmid annotation. Nucleic Acids Res. 2021 Jul 2;49(W1):W516-W522.",
    "link_citation": "https://www.ncbi.nlm.nih.gov/pubmed/34019636",
    "link_software": "https://github.com/barricklab/pLannotate",
    "software": "pLannotate 1.2.0",
    "tool_scheme": "1.0",
    "category": "annotation",
    "worker": "server",
    "queue": "small",
    "threads": 1,
    "enabled": true,
    "components": {
      "DialogStart": {
        "description": "Start a pLannotate annotaion Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track.",
        "valid": false
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for these features or use the defaults."
      },
      "ReportCard": true,
      "FeaturesDataCard": true,
      "MapDataCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/cgview.fa"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "pLannotate"
      },
      "detailed": {
        "type": "switch",
        "target": "DialogStart",
        "default": false,
        "help": "Uses modified algorithm for a more-detailed search with more false positives."
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "pLannotate Annotation"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "pLannotate",
        "additionalItems": {
          "BY_TYPE": "By Type"
        },
        "default": "BY_TYPE",
        "help": "Choose or create a new legend for the added features. If By Type is chosen, the feature type will be matched with a legend of the same name. If no legend exists for a type, it will be created."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_plannotate/output.csv",
            "description": "Comma-separated (CSV) file features found."
          },
          {
            "path": "output_plannotate/output.gbk",
            "description": "Genbank file with Features."
          },
          {
            "path": "output_plannotate/output.html",
            "description": "HTML file with SVG image of map with features."
          }
        ],
        "helpfulLinks": [
          {
            "name": "pLannotate on Github",
            "url": "https://github.com/barricklab/pLannotate"
          },
          {
            "name": "pLannotate Homepage",
            "url": "http://plannotate.barricklab.org/"
          }
        ]
      },
      "features": {
        "name": "Features",
        "type": "dataCard",
        "component": "FeaturesDataCard",
        "data": "output_plannotate/output.csv"
      },
      "map": {
        "name": "Map",
        "type": "dataCard",
        "component": "MapDataCard",
        "embed": true,
        "embedType": "html",
        "data": "output_plannotate/output.html"
      }
    }
  },
  {
    "name": "Features",
    "id": "features",
    "description": "Upload feature data from a text file (e.g. CSV, GFF3, GTF, BED).",
    "author": "Jason R. Grant",
    "version": "2.0.3",
    "tool_scheme": "1.0",
    "category": "user",
    "worker": "client",
    "components": {
      "DialogAdd": {
        "title": "Add Features",
        "valid": false
      },
      "FeatureHelp": true
    },
    "inputs": {
      "file": {
        "name": "Feature File",
        "type": "file",
        "sourcesAllowed": [
          "upload"
        ],
        "help": "Upload a file (e.g. CSV/TSV, GFF3, GTF, BED) of features to add.",
        "uploadPlaceHolder": "Upload feature file...",
        "fileType": "feature",
        "useSettings": true,
        "validationRules": {
          "validFileFormats": [
            "csv",
            "tsv",
            "gff3",
            "gtf",
            "bed"
          ],
          "validCount": [
            1,
            10000
          ]
        }
      },
      "format": {
        "type": "select",
        "name": "Feature File Format",
        "target": "Settings",
        "values": {
          "auto": "Auto Detect",
          "csv": "CSV",
          "tsv": "TSV",
          "gff3": "GFF3",
          "gtf": "GTF",
          "bed": "BED"
        },
        "default": "auto"
      },
      "swapStartStop": {
        "type": "switch",
        "name": "Swap start/stop position for these feature?",
        "target": "DialogAdd",
        "default": false,
        "autoSave": false
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack"
      },
      "legend": {
        "type": "selectLegend",
        "autoSave": false,
        "useFor": "dataLegend",
        "additionalItems": {
          "BY_TYPE": "By Type"
        },
        "default": "BY_TYPE",
        "help": "Choose or create a new legend for the added features. If By Type is chosen, the feature type will be matched with a legend of the same name. If no legend exists for a type, it will be created."
      }
    }
  },
  {
    "name": "Map Builder",
    "short_name": "Builder",
    "id": "cgview_builder",
    "description": "Builds the initial CGView JSON file from a GenBank, EMBL, FASTA or raw sequence.",
    "author": "Jason R. Grant",
    "citation": "Not Published Yet",
    "version": "2.0.5",
    "software": "CGParse 1.0.5",
    "tool_scheme": "1.0",
    "category": "build",
    "worker": "server",
    "queue": "small",
    "hidden": true,
    "components": {
      "DialogStart": true,
      "ReportCard": true
    },
    "inputs": {
      "sequence": {
        "type": "file",
        "fileType": "sequence",
        "path": "input/"
      },
      "project_id": {
        "type": "proksee",
        "data": "project_id"
      },
      "project_name": {
        "type": "proksee",
        "data": "project_name"
      },
      "nameKeys": {
        "type": "select",
        "target": "New",
        "values": [

        ],
        "default": [
          "gene",
          "locus_tag",
          "product",
          "note",
          "db_xref",
          "type"
        ]
      },
      "includeFeatures": {
        "type": "select",
        "target": "New",
        "values": [
          "all",
          "none",
          "include",
          "exclude"
        ],
        "default": "exclude"
      },
      "featureTypes": {
        "type": "select",
        "target": "New",
        "values": [

        ],
        "default": [
          "gene",
          "source",
          "exon"
        ]
      },
      "includeQualifiers": {
        "type": "select",
        "target": "New",
        "values": [
          "all",
          "none",
          "include",
          "exclude"
        ],
        "default": "none"
      },
      "qualifierTypes": {
        "type": "select",
        "target": "New",
        "values": [

        ],
        "default": [

        ]
      }
    },
    "outputs": {
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "output_cgview_builder/cgview.json",
        "featuredFiles": [
          {
            "path": "output_cgview_builder/cgview.json",
            "description": "Initial CGView JSON file for this project."
          }
        ],
        "helpfulLinks": [
          {
            "name": "CGView JSON Details",
            "url": "https://js.cgview.ca/json.html"
          }
        ]
      }
    }
  },
  {
    "name": "Bakta",
    "id": "bakta",
    "version": "1.1.0",
    "description": "Annotate the genome sequence with Bakta.",
    "author": "Jason R. Grant",
    "citation": "Schwengers O, Jelonek L, Dieckmann MA, Beyvers S, Blom J, Goesmann A. Bakta: rapid and standardized annotation of bacterial genomes via alignment-free sequence identification. Microb Genom. 2021 Nov;7(11):000685.",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/34739369/",
    "link_software": "https://github.com/oschwengers/bakta",
    "software": "Bakta v1.8.2 (DB: v5.0 - Light)",
    "tool_scheme": "1.0",
    "category": "annotation",
    "worker": "server",
    "queue": "small",
    "threads": 4,
    "docker": "oschwengers/bakta:v1.8.2",
    "data": true,
    "components": {
      "DialogStart": {
        "description": "Start a Bakta annotation Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track."
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for the selected features or use the defaults."
      },
      "ReportCard": true,
      "BaktaDataCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/cgview.fa"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "bakta"
      },
      "genetic_code": {
        "type": "select",
        "name": "Genetic Code",
        "target": "DialogStart",
        "values": {
          "4": "4 - Mold, Protozoan, and Coelenterate Mitochondrial (and also Mycoplasma/Spiroplasma)",
          "11": "11 - Bacterial and Plant Plastid"
        },
        "default": "11",
        "help": "The genetic code to use for DNA translation."
      },
      "gram": {
        "type": "select",
        "name": "Gram Type",
        "target": "DialogStart",
        "values": {
          "+": "Gram-positive (monoderm)",
          "-": "Gram-negative (diderm)",
          "?": "Unknown"
        },
        "default": "?",
        "help": "Gram type for signal peptide predictions."
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "Bakta Annotation"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "Bakta",
        "additionalItems": {
          "BY_CATEGORY": "By Type"
        },
        "default": "BY_TYPE",
        "help": "Choose or create a new legend for the added features. If By Type is chosen, the feature type will be matched with a legend of the same name. If no legend exists for a type, it will be created."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "output_bakta/bakta.txt",
        "featuredFiles": [
          {
            "path": "output_bakta/bakta.tsv",
            "description": "Tab-separated (TSV) file of all the features found by Bakta."
          },
          {
            "path": "output_bakta/bakta.gff3",
            "description": "Bakta annotations in GFF3 format. Contains sequences and annotations."
          },
          {
            "path": "output_bakta/bakta.gbff",
            "description": "Genbank file with features. Contains one record for each contig."
          },
          {
            "path": "output_bakta/bakta.json",
            "description": "JSON file of reults."
          }
        ],
        "helpfulLinks": [
          {
            "name": "Bakta homepage",
            "url": "https://bakta.computational.bio"
          },
          {
            "name": "Bakta on Github",
            "url": "https://github.com/oschwengers/bakta"
          }
        ]
      },
      "bakta": {
        "name": "Bakta Data",
        "type": "dataCard",
        "component": "BaktaDataCard",
        "data": "output_bakta/bakta.tsv"
      }
    }
  },
  {
    "name": "BLAST",
    "id": "blast",
    "version": "1.4.1",
    "description": "Perform a BLAST comparision with another genome sequence.",
    "author": "Jason R. Grant",
    "citation": "Altschul, S.F., Gish, W., Miller, W., Myers, E.W. & Lipman, D.J. (1990) \"Basic local alignment search tool.\" J. Mol. Biol. 215:403-410.",
    "link_citation": "https://www.ncbi.nlm.nih.gov/pubmed/2231712",
    "link_software": "https://blast.ncbi.nlm.nih.gov/Blast.cgi",
    "software": "BLAST+ 2.15.0",
    "tool_scheme": "1.0",
    "category": "comparison",
    "worker": "server",
    "queue": "medium",
    "threads": 8,
    "docker": "staphb/blast:2.15.0",
    "components": {
      "DialogStart": true,
      "DialogAdd": {
        "description": "Choose a Track and Legend for these features or use the defaults."
      },
      "ReportCard": true,
      "BlastDataCard": true
    },
    "inputs": {
      "cgv": {
        "type": "cgview",
        "data": "cgview",
        "path": "input/"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "BLAST",
        "autoSave": false
      },
      "subject": {
        "type": "file",
        "fileType": "sequence",
        "name": "BLAST Subject",
        "target": "DialogStart",
        "sourcesAllowed": [
          "upload",
          "ncbi"
        ],
        "help": "Upload a comparison genome.",
        "uploadPlaceHolder": "Upload a sequence file (e.g. GenBank, EMBL, FASTA)...",
        "path": "input/",
        "autoSave": false
      },
      "query_type": {
        "type": "select",
        "name": "Query (Map)",
        "target": "DialogStart",
        "help": "&nbsp;",
        "values": {
          "dna": "DNA",
          "translated": "Translated DNA",
          "cds": "CDS (Features)",
          "orfs": "ORFs"
        },
        "default": "dna"
      },
      "subject_type": {
        "type": "select",
        "name": "Subject",
        "target": "DialogStart",
        "help": "&nbsp;",
        "values": {
          "dna": "DNA",
          "translated": "Translated DNA",
          "cds": "CDS",
          "orfs": "ORFs",
          "protein": "Protein"
        },
        "autoSave": false
      },
      "e_cutoff": {
        "type": "select",
        "name": "Expect value cutoff",
        "target": "DialogStart",
        "values": [
          "1.0e-20",
          "1.0e-15",
          "1.0e-10",
          "1.0e-05",
          "0.0001",
          "0.001",
          "0.01",
          "0.1",
          "1",
          "10",
          "100",
          "1000"
        ],
        "default": "0.0001"
      },
      "filter": {
        "type": "switch",
        "name": "Filter low complexity sequences",
        "target": "DialogStart",
        "default": true
      },
      "genetic_code": {
        "type": "select",
        "name": "Genetic Code",
        "target": "DialogStart",
        "values": {
          "0": "Map Default",
          "1": "1 - Standard",
          "2": "2 - Vertebrate Mitochondrial",
          "3": "3 - Yeast Mitochondrial",
          "4": "4 - Mold, Protozoan, and Coelenterate Mitochondrial (and also Mycoplasma/Spiroplasma)",
          "5": "5 - Invertebrate Mitochondrial",
          "6": "6 - Ciliate, Dasycladacean and Hexamita Nuclear",
          "9": "9 - Echinoderm and Flatworm Mitochondrial",
          "10": "10 - Euplotid Nuclear",
          "11": "11 - Bacterial and Plant Plastid",
          "12": "12 - Alternative Yeast Nuclear",
          "13": "13 - Ascidian Mitochondrial",
          "14": "14 - Alternative Flatworm Mitochondrial",
          "15": "15 - Blepharisma Nuclear",
          "16": "16 - Chlorophycean Mitochondrial",
          "21": "21 - Trematode Mitochondrial",
          "22": "22 - Scenedesmus obliquus Mitochondrial",
          "23": "23 - Thraustochytrium Mitochondrial"
        },
        "default": "0",
        "help": "The genetic code to use for translated BLAST searches. Map Default (Recommended) will use the map genetic code and default translations for CDS. Choosing a different code will force all translations to use that code."
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "BLAST Results",
        "separateFeaturesBy": "none",
        "drawOrder": "score"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "BLAST",
        "default": "NEW",
        "autoSave": false,
        "help": "Choose or create a new legend for the added features."
      },
      "skip_enclosed": {
        "type": "switch",
        "name": "Skip Enclosed Lower-Scored Features",
        "target": "DialogAdd",
        "default": true,
        "help": "When enabled, lower-scored features (blast hits) that are enclosed by higher-scored features will not be added to the map. This improves map drawing performance by removing features that would not typically be visible on the map."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_blast/blast_results.tsv",
            "description": "Tab-separated (TSV) file of the BLAST results."
          }
        ],
        "helpfulLinks": [
          {
            "name": "BLAST at NCBI",
            "url": "https://blast.ncbi.nlm.nih.gov"
          }
        ]
      },
      "blast": {
        "name": "BLAST Data",
        "type": "dataCard",
        "component": "BlastDataCard",
        "data": "output_blast/blast_results.tsv"
      }
    }
  },
  {
    "name": "Proksee Assemble",
    "short_name": "Assemble",
    "id": "assemble",
    "version": "1.3.0",
    "description": "Assemble sequencing reads with Proksee Assemble.",
    "author": "Eric Marinier",
    "citation": "",
    "link_citation": "",
    "link_software": "https://github.com/proksee-project/proksee-cmd",
    "software": "Proksee 1.0.0a6",
    "tool_scheme": "1.0",
    "category": "assembly",
    "worker": "server",
    "queue": "large",
    "hidden": true,
    "threads": 16,
    "memory": 28,
    "docker": "prokan/assemble:0.2.0",
    "components": {
      "ReportCard": true,
      "QuastCard": true,
      "IcarusCard": true
    },
    "inputs": {
      "forward_reads": {
        "type": "file",
        "fileType": "fastq",
        "name": "Forward Reads",
        "temp": true,
        "target": "DialogStart",
        "sourcesAllowed": [
          "upload"
        ],
        "validationRules": {
          "validExtensions": [
            "fastq",
            "gz",
            "zip"
          ]
        },
        "help": "Upload the forward reads.",
        "uploadPlaceHolder": "Upload a FASTQ file...",
        "path": "input/"
      },
      "reverse_reads": {
        "type": "file",
        "fileType": "fastq",
        "name": "Reverse Reads",
        "temp": true,
        "target": "DialogStart",
        "sourcesAllowed": [
          "upload"
        ],
        "validationRules": {
          "validExtensions": [
            "fastq",
            "gz",
            "zip"
          ]
        },
        "help": "Upload the reverse reads.",
        "uploadPlaceHolder": "Upload a FASTQ file...",
        "path": "input/"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "Proksee Assemble"
      },
      "species": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Species",
        "default": "Detect",
        "help": "Must be spelled correctly."
      },
      "min_contig_length": {
        "type": "numberInput",
        "target": "DialogStart",
        "name": "Minimum Contig Length",
        "default": 500,
        "help": "Contigs less than this length are not included in the final assembly."
      }
    },
    "outputs": {
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "output_assemble/assembly_info.json",
        "featuredFiles": [
          {
            "path": "output_assemble/contigs.filtered.fasta",
            "description": "FASTA file of filtered assembled contigs. These are used to create the project map."
          },
          {
            "path": "output_assemble/contigs.all.fasta",
            "description": "FASTA file of all assembled contigs"
          },
          {
            "path": "output_assemble/assembly_info.json",
            "description": "Summary of assembly results in JSON. This file is used to create the report on this page."
          },
          {
            "path": "output_assemble/assembly_statistics.csv",
            "description": "Comparison of the assembly statistics between Skesa and SPAdes."
          },
          {
            "path": "output_assemble/quast/report.pdf",
            "description": "QUAST evaluation of the genome assembly (PDF)."
          },
          {
            "path": "output_assemble/quast/icarus_viewers/contig_size_viewer.html",
            "description": "Icarus evaluation of the genome assembly (HTML)."
          }
        ],
        "helpfulLinks": [
          {
            "name": "Proksee Assemble Homepage",
            "url": "https://proksee-project.github.io/proksee-cmd/"
          },
          {
            "name": "Proksee Assemble on Github",
            "url": "https://github.com/proksee-project/proksee-cmd"
          },
          {
            "name": "QUAST output manual",
            "url": "https://quast.sourceforge.net/docs/manual.html#sec3"
          }
        ]
      },
      "icarus": {
        "name": "Icarus",
        "type": "dataCard",
        "component": "IcarusCard",
        "embed": true,
        "embedType": "html",
        "data": "output_assemble/quast/icarus_viewers/contig_size_viewer.html"
      },
      "quast": {
        "name": "QUAST",
        "type": "dataCard",
        "component": "QuastCard",
        "embed": true,
        "embedType": "pdf",
        "data": "output_assemble/quast/report.pdf"
      }
    }
  },
  {
    "name": "PHASTEST",
    "id": "phastest",
    "version": "0.1.0",
    "description": "Detect and annotate prophage regions.",
    "author": "Jason R. Grant",
    "citation": "Wishart, D.S., Han, S., Saha, S., Oler, E., Peters, H., Grant, J.R., Stothard, P., Gautam, V. PHASTEST: Faster than PHASTER, Better than PHAST, Nucleic Acids Research, Volume 51, Issue W1, 5 July 2023, Pages W443–W450",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/37194694/",
    "link_software": "https://phastest.ca",
    "software": "PHASTEST 3.0",
    "tool_scheme": "1.0",
    "category": "mge",
    "worker": "server",
    "queue": "test",
    "max_run_time": "1h",
    "enabled": false,
    "data": true,
    "components": {
      "DialogStart": {
        "description": "Start a PHASTEST annotation Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track."
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for the selected features or use the defaults."
      },
      "ReportCard": true,
      "PhastestSummaryDataCard": true,
      "PhastestDetailsDataCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/cgview.fa"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "PHASTEST"
      },
      "mode": {
        "type": "select",
        "name": "Annotation Mode",
        "target": "DialogStart",
        "values": {
          "lite": "Lite: uses the Swissprot database",
          "deep": "Deep: uses PHAST-BSD (PHASTEST Bacterial Sequence Database)"
        },
        "default": "deep",
        "help": "Bacterial sequence annotation mode."
      },
      "add_prophage": {
        "name": "Prophage Regions",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "add_genes": {
        "name": "Prophage Genes",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "PHASTEST",
        "separateFeaturesBy": "none"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "PHASTEST",
        "allowGroupItems": true,
        "byKeyItems": [
          "by:meta:completeness",
          "by:type",
          "by:meta:category"
        ],
        "default": "by:meta:category",
        "help": "Choose or create a new legend for the added features.\nIf 'By type/category' is selected, features will be matched with a legend of the same name.\nIf no legend exists for a type/category, it will be created.\n"
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_phastest/summary.txt",
            "description": "Provides an overview of the identified prophage regions."
          },
          {
            "path": "output_phastest/detail.txt",
            "description": "Details (Text) of the features/genes for each prophage region. See json_input for Data Card."
          },
          {
            "path": "output_phastest/json_input",
            "description": "Details (JSON) of the features/genes for each prophage region."
          },
          {
            "path": "output_phastest/json_input_regions",
            "description": "JSON file of phage regions."
          },
          {
            "path": "output_phastest/region_DNA.txt",
            "description": "FASTA file of phage regions. Contains one record for each region."
          },
          {
            "path": "output_phastest/job.log",
            "description": "Internal PHASTEST log. Check this for more details if the job fails."
          }
        ],
        "helpfulLinks": [
          {
            "name": "PHASTEST homepage",
            "url": "https://phastest.ca"
          }
        ]
      },
      "summary": {
        "name": "Summary",
        "type": "dataCard",
        "component": "PhastestSummaryDataCard",
        "data": "output_phastest/summary.txt"
      },
      "details": {
        "name": "Details",
        "type": "dataCard",
        "component": "PhastestDetailsDataCard",
        "data": "output_phastest/json_input"
      }
    },
    "stubs": [
      {
        "path": "output_phastest/job.fna",
        "note": "This file contains the same sequence(s) as in 'input/cgview.fa' with different formatting and fasta descriptions lines."
      }
    ]
  },
  {
    "name": "FastANI",
    "id": "fastani",
    "version": "1.1.0",
    "description": "Fast alignment-free computation of whole-genome Average Nucleotide Identity (ANI).",
    "author": "Jason R. Grant",
    "citation": "Jain C, Rodriguez-R LM, Phillippy AM, Konstantinidis KT, Aluru S. High throughput ANI analysis of 90K prokaryotic genomes reveals clear species boundaries. Nat Commun. 2018 Nov 30;9(1):5114.",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/30504855/",
    "link_software": "https://github.com/ParBLiSS/FastANI",
    "software": "FastANI 1.34",
    "tool_scheme": "1.0",
    "category": "comparison",
    "worker": "server",
    "queue": "small",
    "threads": 4,
    "components": {
      "DialogStart": {
        "description": "Start a FastANI (Average Nucleotide Identity) comparison of your project genome with another genome. Once the Job is complete the results can be viewed in the Jobs pane.",
        "valid": false
      },
      "ReportCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/reference"
      },
      "query": {
        "type": "file",
        "fileType": "sequence",
        "name": "Query",
        "target": "DialogStart",
        "sourcesAllowed": [
          "upload"
        ],
        "help": "Upload a single query genome sequence file (fasta or multi-fasta).",
        "uploadPlaceHolder": "Upload fasta file",
        "path": "input/",
        "validationRules": {
          "validFileFormats": "FASTA",
          "validMolTypes": "DNA"
        }
      },
      "queryName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Query Name",
        "default": "Query"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "FastANI"
      }
    },
    "outputs": {
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "output_fastani/fastani.out",
        "featuredFiles": [
          {
            "path": "output_fastani/fastani.out",
            "description": "Results of FastANI. This file will be empty if no ANI output is reported."
          },
          {
            "path": "output_fastani/fastani.out.svg",
            "description": "SVG image (shown above) of reciprocal mapping betwen the query and the reference genomes."
          }
        ],
        "helpfulLinks": [
          {
            "name": "FastANI on Github",
            "url": "https://github.com/ParBLiSS/FastANI"
          }
        ]
      }
    }
  },
  {
    "name": "ORFs",
    "id": "orfs",
    "description": "Find Open Reading Frames (ORFs)",
    "author": "Jason R. Grant",
    "version": "1.0.1",
    "tool_scheme": "1.0",
    "category": "annotation",
    "worker": "client",
    "components": {
      "DialogAdd": {
        "title": "Add ORFs"
      }
    },
    "inputs": {
      "trackName": {
        "type": "textInput",
        "target": "DialogAdd",
        "name": "Track Name",
        "default": "ORFs"
      },
      "minSize": {
        "type": "numberInput",
        "target": "DialogAdd",
        "name": "Minimum ORF Size",
        "default": 100,
        "min": 30,
        "help": "Minimum ORF size (codons/aa). Cutoff size does not include the stop codon and must be greater than or equal to 30 codons"
      }
    }
  },
  {
    "name": "BLAST Formatter",
    "id": "blast_formatter",
    "description": "Color existing BLAST features by percent identity and sort BLAST tracks by similarity.",
    "author": "Jason R. Grant",
    "version": "1.0.3",
    "tool_scheme": "1.0",
    "category": "refinement",
    "worker": "client",
    "components": {
      "DialogAdd": {
        "title": "BLAST Formatter",
        "valid": false
      }
    },
    "inputs": {
      "sortTracks": {
        "type": "switch",
        "target": "DialogAdd",
        "default": true,
        "name": "Sort BLAST Tracks"
      },
      "sortOrderMostSimilarInside": {
        "type": "switch",
        "target": "DialogAdd",
        "default": true,
        "onString": "Inside",
        "offString": "Outside",
        "name": "Order (Most Similar)"
      },
      "cutoffs": {
        "type": "select",
        "name": "Percent Identity Cutoffs",
        "target": "DialogAdd",
        "help": "Features (BLAST hits) are colored based on their percent identity where different colors are used for a range of percent identity values from one cutoff value to the next. Use one of the predefined cutoff sets or click to customize.",
        "values": [
          "0,10,20,30,40,50,60,70,80,90,100",
          "0,55,60,65,70,75,80,85,90,95,100",
          "0,82,84,86,88,90,92,94,96,98,100",
          "0,91,92,93,94,95,96,97,98,99,100"
        ],
        "default": "0,82,84,86,88,90,92,94,96,98,100"
      },
      "colorScheme": {
        "type": "select",
        "name": "Color Scheme",
        "target": "DialogAdd",
        "help": "Choose a color scheme for the BLAST hits.",
        "values": {
          "Blues": "Blues",
          "Greens": "Greens",
          "Greys": "Greys",
          "Oranges": "Oranges",
          "Purples": "Purples",
          "Reds": "Reds",
          "BuGn": "Blue → Green",
          "BuPu": "Blue → Purple",
          "GnBu": "Green → Blue",
          "OrRd": "Orange → Red",
          "PuBuGn": "Purple → Blue → Green",
          "PuBu": "Purple → Blue",
          "PuRd": "Purple → Red",
          "RdPu": "Red → Purple",
          "YlGnBu": "Yellow → Green → Blue",
          "YlGn": "Yellow → Green",
          "YlOrBr": "Yellow → Orange → Brown",
          "YlOrRd": "Yellow → Orange → Red"
        },
        "default": "PuRd"
      },
      "hideLowerScoredEnclosedFeatures": {
        "type": "switch",
        "default": true,
        "target": "DialogAdd",
        "name": "Hide BLAST hits with lower percent identities",
        "help": "Features (BLAST hits) that are completely enclosed within an other feature with higher percent identity will be hidden. This typically results in a better looking map and improved performance."
      },
      "removePreviousLegends": {
        "type": "switch",
        "default": true,
        "target": "DialogAdd",
        "name": "Delete previous legends",
        "help": "Delete legends (from previous BLAST tracks) that no longer have any features."
      }
    }
  },
  {
    "name": "MITOS",
    "id": "mitos",
    "version": "1.1.3",
    "description": "Annotate metazoan mitochondrial genomes with MITOS 2. Limited to genomes up to 50 kbp and 10 contigs.",
    "author": "Jason R. Grant",
    "citation": "Donath, A. et al. Improved annotation of protein-coding genes boundaries in metazoan mitochondrial genomes. Nucleic Acids Res. 47, 10543–10552 (2019)",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/31584075",
    "link_software": "https://gitlab.com/Bernt/MITOS",
    "software": "Mitos 2.1.7",
    "tool_scheme": "1.0",
    "category": "annotation",
    "worker": "server",
    "queue": "small",
    "threads": 4,
    "data": true,
    "docker": "quay.io/biocontainers/mitos:2.1.7--pyhdfd78af_1",
    "components": {
      "DialogStart": {
        "description": "Start a Mitos annotation Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track.",
        "valid": false
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for these features or use the defaults."
      },
      "ReportCard": true,
      "MitosDataCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "Mitos"
      },
      "reference": {
        "type": "select",
        "target": "DialogStart",
        "values": {
          "refseq63f": "RefSeq 63 Fungi",
          "refseq63m": "RefSeq 63 Metazoa",
          "refseq63o": "RefSeq 63 Opisthokonta",
          "refseq89f": "RefSeq 89 Fungi",
          "refseq89m": "RefSeq 89 Metazoa",
          "refseq89o": "RefSeq 89 Opisthokonta"
        },
        "default": "refseq89m",
        "help": "Reference Data"
      },
      "genetic_code": {
        "name": "Genetic Code",
        "type": "select",
        "target": "DialogStart",
        "values": {
          "2": "2 - Vertebrate Mitochondrial",
          "4": "4 - Mold, Protozoan, and Coelenterate Mitochondrial (and also Mycoplasma/Spiroplasma)",
          "5": "5 - Invertebrate Mitochondrial",
          "9": "9 - Echinoderm and Flatworm Mitochondrial",
          "13": "13 - Ascidian Mitochondrial",
          "14": "14 - Alternative Flatworm Mitochondrial"
        },
        "default": "2",
        "help": "The genetic code to use for DNA translation."
      },
      "linear": {
        "name": "Linear Genome",
        "type": "switch",
        "target": "DialogStart",
        "default": false,
        "help": "Treat the sequence as linear."
      },
      "keep": {
        "name": "Keep Intermediate Files",
        "type": "switch",
        "target": "DialogStart",
        "default": false,
        "help": "Keep intermediate files (e.g. blast results). These files contribute to ~95% of the job size."
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "Mitos Annotation"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "Mitos",
        "additionalItems": {
          "BY_TYPE": "By Type"
        },
        "default": "BY_TYPE",
        "help": "Choose or create a new legend for the added features. If By Type is chosen, the feature type will be matched with a legend of the same name. If no legend exists for a type, it will be created."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_mitos/result.mitos",
            "description": "Results of the Mitos annotation."
          },
          {
            "path": "output_mitos/result.bed",
            "description": "BED file of all the features found by Mitos."
          },
          {
            "path": "output_mitos/result.gff",
            "description": "Mitos annotations in GFF format."
          },
          {
            "path": "output_mitos/result.geneorder",
            "description": "The gene order for the annotated genome."
          },
          {
            "path": "output_mitos/result.faa",
            "description": "FASTA file of annotation sequences (amino acid)."
          },
          {
            "path": "output_mitos/result.fas",
            "description": "FASTA file of annotation sequences (DNA)."
          }
        ],
        "helpfulLinks": [
          {
            "name": "Mitos WebServer",
            "url": "http://mitos2.bioinf.uni-leipzig.de/index.py"
          },
          {
            "name": "Mitos on GitLab",
            "url": "https://gitlab.com/Bernt/MITOS"
          }
        ]
      },
      "mitos": {
        "name": "Mitos Data",
        "type": "dataCard",
        "component": "MitosDataCard",
        "data": "output_mitos/result.mitos"
      }
    }
  },
  {
    "name": "eggNOG Mapper",
    "short_name": "eggNOG",
    "id": "eggnog",
    "description": "Find functional annotations for the selected sequences using eggNOG-mapper.",
    "author": "Jason R. Grant",
    "citation": "Huerta-Cepas et al. 2019. eggNOG 5.0: a hierarchical, functionally and phylogenetically annotated orthology resource based on 5090 organisms and 2502 viruses. Nucleic Acids Research, 47, D309-D314",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/30418610/",
    "link_software": "http://eggnog-mapper.embl.de",
    "version": "0.1.0",
    "software": "eggnog-mapper 2.0.1",
    "tool_scheme": "1.0",
    "category": "annotation",
    "worker": "server",
    "queue": "small",
    "threads": 4,
    "enabled": false,
    "components": {
      "DialogStart": true,
      "DialogAdd": true,
      "EggNogDataCard": true
    },
    "inputs": {
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "EggNOG Mapper"
      },
      "sequences": {
        "type": "sequenceSelector",
        "name": "Sequences to Annotate",
        "target": "DialogStart",
        "help": "Choose the sequences to submit to eggNOG for annotation",
        "path": "input/"
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "eggNOG Results"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "eggNOG",
        "additionalItems": {
          "BY_COG": "By COG"
        },
        "default": "BY_COG",
        "help": "Choose or create a new legend for the added features."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "eggnog": {
        "name": "EggNog Data",
        "type": "dataCard",
        "component": "EggNogDataCard",
        "data": "output_eggnog/proksee.emapper.annotations"
      }
    }
  },
  {
    "name": "Track List Caption",
    "id": "track_lister",
    "description": "Create a track figure caption describing all the visible tracks.",
    "author": "Jason R. Grant",
    "version": "1.2.0",
    "tool_scheme": "1.0",
    "category": "refinement",
    "worker": "client",
    "components": {
      "DialogAdd": {
        "title": "Track List Caption",
        "description": "Create a list of visible track rings (circular) or lanes (linear). The results can be copied to the clipboard or added to the map as a caption by clicking OK."
      }
    },
    "inputs": {
      "collapse_tracks": {
        "type": "switch",
        "target": "DialogAdd",
        "default": false,
        "name": "Collapse List"
      },
      "start_outside": {
        "type": "switch",
        "target": "DialogAdd",
        "default": true,
        "onString": "Outside",
        "offString": "Inside",
        "name": "Start From"
      },
      "map_format": {
        "type": "select",
        "name": "Map Format",
        "target": "DialogAdd",
        "values": {
          "linear": "Linear (Lanes)",
          "circular": "Circular (Rings)"
        },
        "default": "circular"
      },
      "separator": {
        "type": "select",
        "name": "List Separator",
        "target": "DialogAdd",
        "values": {
          "return": "Return",
          "semicolon": "Semicolon",
          "comma": "Comma"
        },
        "default": "return"
      }
    }
  },
  {
    "name": "CARD Resistance Gene Identifier",
    "short_name": "CARD RGI",
    "id": "card",
    "description": "The Comprehensive Antibiotic Resistance Database (CARD) Resistance Gene Identifier (RGI)",
    "author": "Jason R. Grant",
    "citation": "Alcock et al. 2023. CARD 2023: expanded curation, support for machine learning, and resistome prediction at the Comprehensive Antibiotic Resistance Database. Nucleic Acids Research, 51(D1):D690-D699",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/36263822/",
    "link_software": "https://card.mcmaster.ca",
    "version": "1.2.1",
    "software": "RGI 6.0.3",
    "tool_scheme": "1.0",
    "category": "annotation",
    "worker": "server",
    "queue": "medium",
    "threads": 4,
    "docker": "quay.io/biocontainers/rgi:6.0.3--pyha8f3691_0",
    "components": {
      "DialogStart": true,
      "DialogAdd": {
        "description": "Choose a Track and Legend for these features or use the defaults."
      },
      "ReportCard": true,
      "CARDDataCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "CARD RGI"
      },
      "include_loose": {
        "type": "switch",
        "name": "Include Loose Hits",
        "target": "DialogStart",
        "default": false,
        "help": "Include loose hits in addition to strict and perfect hits"
      },
      "include_nudge": {
        "type": "switch",
        "name": "Include Nudged Hits",
        "target": "DialogStart",
        "default": false,
        "help": "Include hits nudged from loose to strict hits"
      },
      "low_quality": {
        "type": "switch",
        "name": "Low Quality Sequence",
        "target": "DialogStart",
        "default": false,
        "help": "Use for short contigs to predict partial genes"
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "CARD RGI Results"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "CARD",
        "defaultNewItemColor": "rgba(200, 50, 0, 1)",
        "default": "NEW",
        "help": "Choose or create a new legend for the added features."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_card/output.txt",
            "description": "Tab-separated (TSV) file of all the features found by CARD."
          }
        ],
        "helpfulLinks": [
          {
            "name": "CARD Homepage",
            "url": "https://card.mcmaster.ca/"
          },
          {
            "name": "CARD RGI on Github",
            "url": "https://github.com/arpcard/rgi"
          }
        ]
      },
      "card": {
        "name": "CARD Data",
        "type": "dataCard",
        "component": "CARDDataCard",
        "data": "output_card/output.txt"
      }
    }
  },
  {
    "name": "Phigaro",
    "id": "phigaro",
    "version": "1.0.1",
    "description": "Detect and annotate prophage regions.",
    "author": "Jason R. Grant",
    "citation": "Starikova EV, Tikhonova PO, Prianichnikov NA, Rands CM, Zdobnov EM, et al.. Phigaro: high-throughput prophage sequence annotation. Bioinformatics. 2020 Jun 1;36(12):3882-3884.",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/32311023/",
    "link_software": "https://github.com/bobeobibo/phigaro/",
    "software": "Phigaro 2.3.0",
    "tool_scheme": "1.0",
    "category": "mge",
    "worker": "server",
    "queue": "small",
    "threads": 4,
    "components": {
      "DialogStart": {
        "description": "Start a Phigaro annotation Job using the map sequence. At least one of the contigs must be larger than 20,000 bp.",
        "valid": false
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for the selected features or use the defaults."
      },
      "ReportCard": true,
      "PhigaroCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/cgview.fa"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "Phigaro"
      },
      "add_prophage": {
        "name": "Prophage Regions",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "add_genes": {
        "name": "Prophage Genes",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "Phigaro",
        "separateFeaturesBy": "none"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "Phigaro",
        "additionalItems": {
          "BY_TYPE": "By Type"
        },
        "default": "BY_TYPE",
        "help": "Choose or create a new legend for the added features. If By Type is chosen, the feature type will be matched with a legend of the same name. If no legend exists for a type, it will be created."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_phigaro/cgview.phigaro.html",
            "description": "Self-contained downloadable HTML file created by Phigaro."
          },
          {
            "path": "output_phigaro/cgview.phigaro.tsv",
            "description": "Tab-separated (TSV) file with list of phage regions with taxonomy."
          },
          {
            "path": "output_phigaro/cgview.phigaro.bed",
            "description": "BED file of phage regions and genes."
          },
          {
            "path": "output_phigaro/cgview.phigaro.gff3",
            "description": "GFF file of phage regions and genes "
          },
          {
            "path": "output_phigaro/cgview.phigaro.fasta",
            "description": "FASTA file of phage region sequences."
          }
        ],
        "helpfulLinks": [
          {
            "name": "Phigaro on Github",
            "url": "https://github.com/bobeobibo/phigaro"
          },
          {
            "name": "Phigaro Docs",
            "url": "https://phigaro.readthedocs.io/en/latest/"
          }
        ]
      },
      "phigaro": {
        "name": "Phigaro",
        "type": "dataCard",
        "component": "PhigaroCard",
        "embed": true,
        "embedType": "html",
        "data": "output_phigaro/cgview.phigaro.html"
      }
    }
  },
  {
    "name": "Alien Hunter",
    "id": "alien_hunter",
    "version": "1.2.0",
    "description": "Predict putative Horizontal Gene Transfer (HGT) events. Limited to 1 contig between 20,000 and 1,000,000 bp.",
    "author": "Jason R. Grant",
    "citation": "Vernikos GS, Parkhill J. Interpolated variable order motifs for identification of horizontally acquired DNA: revisiting the Salmonella pathogenicity islands. Bioinformatics. 2006 Sep 15;22(18):2196-203.",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/16837528",
    "link_software": "https://www.sanger.ac.uk/tool/alien-hunter",
    "software": "alien_hunter 1.7",
    "tool_scheme": "1.0",
    "category": "mge",
    "worker": "server",
    "queue": "slow",
    "max_run_time": "1h",
    "threads": 1,
    "docker": "prokan/alien_hunter:0.1.0",
    "components": {
      "DialogStart": {
        "description": "Start an Alien Hunter annotation Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track.",
        "valid": false
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for the selected features or use the defaults."
      },
      "ReportCard": true,
      "HGTRegionsCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/cgview.fa"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "Alien Hunter"
      },
      "optimize_boundaries": {
        "name": "Optimize Boundaries",
        "type": "switch",
        "target": "DialogStart",
        "default": true,
        "help": "Parses predicitions into a 2-state 2nd order Hidden Markov Model (HMM), in a change-point detection framework, to optimize the localization of the boundaries of the predicted regions."
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "Alien Hunter",
        "separateFeaturesBy": "none"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "Alien Hunter",
        "default": "NEW",
        "help": "Choose or create a new legend for the added features."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_alien_hunter/hgt_regions.csv",
            "description": "Comma-separated (CSV) file of all the HGT regions found by Alien Hunter."
          }
        ],
        "helpfulLinks": [
          {
            "name": "Alien Hunter at the Sanger Institute",
            "url": "https://www.sanger.ac.uk/tool/alien-hunter/"
          }
        ]
      },
      "regions": {
        "name": "HGT Regions",
        "type": "dataCard",
        "component": "HGTRegionsCard",
        "data": "output_alien_hunter/hgt_regions.csv"
      }
    }
  },
  {
    "name": "GC Content",
    "id": "gc_content",
    "description": "Add a GC Content plot.",
    "author": "Jason R. Grant",
    "version": "1.0.2",
    "tool_scheme": "1.0",
    "category": "composition",
    "worker": "client",
    "components": {
      "DialogAdd": {
        "title": "Add a GC Content Plot"
      }
    },
    "inputs": {
      "trackName": {
        "type": "textInput",
        "target": "DialogAdd",
        "name": "Track Name",
        "default": "GC Content"
      },
      "window": {
        "name": "Window size (bp)",
        "type": "select",
        "target": "DialogAdd",
        "values": [
          "Auto",
          10,
          50,
          500,
          1000,
          10000,
          50000
        ],
        "default": "Auto",
        "help": "This size of the sliding window (number of nucleotides) used for each calculation."
      },
      "step": {
        "name": "Step size (bp)",
        "type": "select",
        "target": "DialogAdd",
        "values": [
          "Auto",
          1,
          10,
          100,
          500,
          1000
        ],
        "default": "Auto",
        "help": "The number of nucleotides to move the window after each calculation."
      }
    }
  },
  {
    "name": "mobileOG-db",
    "id": "mobile_og_db",
    "version": "1.1.3",
    "description": "Find mobile genetic elements (MGEs).",
    "author": "Jason R. Grant",
    "citation": "Brown CL, Mullet J, Hindi F, Stoll JE, et al. mobileOG-db: a Manually Curated Database of Protein Families Mediating the Life Cycle of Bacterial Mobile Genetic Elements. Appl Environ Microbiol. 2022 Sep 22;88(18)",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/36036594/",
    "link_software": "https://mobileogdb.flsi.cloud.vt.edu",
    "software": "mobileOG-db (beatrix-1.6)",
    "tool_scheme": "1.0",
    "category": "mge",
    "worker": "server",
    "queue": "small",
    "threads": 1,
    "docker": "prokan/mobile_og_db:0.3.0",
    "components": {
      "DialogStart": {
        "description": "Start a mobileOG-db annotation Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track."
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for the selected features or use the defaults."
      },
      "ReportCard": true,
      "AlignmentsCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/cgview.fa"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "mobileOG-db"
      },
      "e_score": {
        "type": "select",
        "name": "Expect value threshold",
        "target": "DialogStart",
        "values": [
          "1.0e-20",
          "1.0e-15",
          "1.0e-10",
          "1.0e-05",
          "0.0001",
          "0.001",
          "0.01",
          "0.1",
          "1",
          "10",
          "100",
          "1000"
        ],
        "default": "1.0e-05",
        "help": "Maximum expected value to keep an alignment. Lower thresholds are more stringent."
      },
      "p_ident_value": {
        "name": "Minimum percent of identical matches to report an alignment",
        "type": "slider",
        "target": "DialogStart",
        "default": 60,
        "min": 20,
        "max": 100,
        "help": "Only alignments above the given percentage of sequence identity are reported. Higher values are more stringent."
      },
      "query_score": {
        "name": "Minimum percent of query coverage to report an alignment",
        "type": "slider",
        "target": "DialogStart",
        "default": 80,
        "min": 50,
        "max": 100,
        "help": "Only alignments that cover the given percentage of the query are reported. Higher values are more stringent."
      },
      "k_value": {
        "name": "Number of Diamond Alignments to Report",
        "type": "slider",
        "target": "DialogStart",
        "default": 1,
        "min": 1,
        "max": 10,
        "help": "Only the top hit is used to create features."
      },
      "add_phage": {
        "name": "Phage",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "add_ie": {
        "name": "Integration/Excision",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "add_rrr": {
        "name": "Replication/Recombination/Repair",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "add_std": {
        "name": "Stability/Transfer/Defense",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "add_transfer": {
        "name": "Transfer",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "mobileOG-db Annotation"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "mobileOG-db",
        "additionalItems": {
          "BY_CATEGORY": "By Category"
        },
        "default": "BY_CATEGORY",
        "help": "Choose or create a new legend for the added features. If By Category is chosen, the feature mobileOG-db category will be matched with a legend of the same name. If no legend exists for a category, it will be created."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "output_mobile_og_db/cgview.fa.mobileOG.Alignment.Out.csv",
        "featuredFiles": [
          {
            "path": "output_mobile_og_db/cgview.fa.mobileOG.Alignment.Out.csv",
            "description": "Comma-separated (CSV) file of features found by mobileOG-db. This file will not exist if no features were found."
          }
        ],
        "helpfulLinks": [
          {
            "name": "mobileOG-db homepage",
            "url": "https://mobileogdb.flsi.cloud.vt.edu/"
          },
          {
            "name": "mobileOG-db on Github",
            "url": "https://github.com/clb21565/mobileOG-db"
          }
        ]
      },
      "alignments": {
        "name": "Alignments",
        "type": "dataCard",
        "component": "AlignmentsCard",
        "data": "output_mobile_og_db/cgview.fa.mobileOG.Alignment.Out.csv"
      }
    }
  },
  {
    "name": "GC Skew",
    "id": "gc_skew",
    "description": "Add a GC Skew plot.",
    "author": "Jason R. Grant",
    "version": "1.0.2",
    "tool_scheme": "1.0",
    "category": "composition",
    "worker": "client",
    "components": {
      "DialogAdd": {
        "title": "Add a GC Skew Plot"
      }
    },
    "inputs": {
      "trackName": {
        "type": "textInput",
        "target": "DialogAdd",
        "name": "Track Name",
        "default": "GC Skew"
      },
      "window": {
        "type": "select",
        "target": "DialogAdd",
        "values": [
          "Auto",
          10,
          50,
          500,
          1000,
          10000,
          50000
        ],
        "default": "Auto",
        "help": "This size of the sliding window (number of nucleotides) used for each calculation."
      },
      "step": {
        "type": "select",
        "target": "DialogAdd",
        "values": [
          "Auto",
          1,
          10,
          100,
          500,
          1000
        ],
        "default": "Auto",
        "help": "The number of nucleotides to move the window after each calculation."
      }
    }
  },
  {
    "name": "VirSorter",
    "id": "virsorter",
    "version": "1.1.1",
    "description": "Detect dsDNA and ssDNA virus genomes (phage).",
    "author": "Jason R. Grant",
    "citation": "Guo J, Bolduc B, Zayed AA, Varsani A, Dominguez-Huerta G, et al. VirSorter2: a multi-classifier, expert-guided approach to detect diverse DNA and RNA viruses. Microbiome. 2021 Feb 1;9(1):37.",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/33522966/",
    "link_software": "https://bitbucket.org/MAVERICLab/virsorter2",
    "software": "VirSorter 2.2.4",
    "tool_scheme": "1.0",
    "category": "mge",
    "worker": "server",
    "queue": "slow",
    "threads": 2,
    "components": {
      "DialogStart": {
        "description": "Start a VirSorter annotation Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track."
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for the selected features or use the defaults."
      },
      "ReportCard": true,
      "ScoreCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/cgview.fa"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "VirSorter"
      },
      "min_length": {
        "type": "select",
        "name": "Minimum Sequence Length",
        "target": "DialogStart",
        "values": [
          "100",
          "500",
          "1000",
          "5000",
          "10000",
          "50000",
          "100000"
        ],
        "default": "500",
        "help": "Contigs less than this length will be ignored which will speed up run time."
      },
      "min_score": {
        "name": "Minimum Score",
        "type": "slider",
        "target": "DialogStart",
        "default": 0.5,
        "min": 0.4,
        "step": 0.05,
        "max": 1,
        "help": "The minimal score to be identified as viral."
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "VirSorter",
        "separateFeaturesBy": "none"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "VirSorter"
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_virsorter/final-viral-score.tsv",
            "description": "Tab-separated (TSV) file with scores of each viral sequences across groups."
          },
          {
            "path": "output_virsorter/final-viral-boundary.tsv",
            "description": "Tab-separated (TSV) file of viral sequences boundaries."
          },
          {
            "path": "output_virsorter/final-viral-combined.fa",
            "description": "FASTA file of indentifed viral sequences."
          }
        ],
        "helpfulLinks": [
          {
            "name": "VirSorter on Github",
            "url": "https://github.com/jiarong/VirSorter2"
          },
          {
            "name": "Getting started with VirSorter2",
            "url": "https://www.protocols.io/view/getting-started-with-virsorter2-rm7vz8rm5vx1/v2"
          }
        ]
      },
      "score": {
        "name": "Scores",
        "type": "dataCard",
        "component": "ScoreCard",
        "data": "output_virsorter/final-viral-score.tsv"
      }
    }
  },
  {
    "name": "CRISPR/Cas Finder",
    "id": "crispr_cas_finder",
    "version": "1.1.0",
    "description": "Find CRISPR arrays and their associated Cas proteins.",
    "author": "Jason R. Grant",
    "citation": "Couvin D, Bernheim A, Toffano-Nioche C, Touchon M. et al. CRISPRCasFinder, an update of CRISRFinder, includes a portable version, enhanced performance and integrates search for Cas proteins. Nucleic Acids Res. 2018 Jul 2;46(W1):W246-W251",
    "link_citation": "https://pubmed.ncbi.nlm.nih.gov/29790974",
    "link_software": "https://crisprcas.i2bc.paris-saclay.fr",
    "software": "CRISPRCasFinder 4.2.20",
    "tool_scheme": "1.0",
    "category": "annotation",
    "worker": "server",
    "queue": "small",
    "threads": 1,
    "docker": "prokan/crispr_cas_finder:1.0.0",
    "components": {
      "DialogStart": {
        "description": "Start a CRISPR Cas Finder annotation Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track."
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for the selected features or use the defaults."
      },
      "ReportCard": true,
      "HTMLDataCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/cgview.fa"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "CRISPR/Cas Finder"
      },
      "cas": {
        "name": "Find Cas Genes",
        "type": "switch",
        "target": "DialogStart",
        "default": true,
        "help": "Search for corresponding Cas genes."
      },
      "definition": {
        "name": "Cas Finder Precision",
        "type": "select",
        "target": "DialogStart",
        "values": {
          "General": "General   - (Most Sensitive/Least Precise)",
          "Typing": "Typing    - (In between General and SubTyping)",
          "SubTyping": "SubTyping - (Least Sensitive/Most Precise)"
        },
        "default": "General",
        "help": "Level of precision/sensitivity for detecting Cas genes"
      },
      "keep": {
        "name": "Keep Temporary Files",
        "type": "switch",
        "target": "DialogStart",
        "default": false,
        "help": "Keep temporary folders/files (e.g. Prodigal, Cas-finder, rawFASTA, Properties)."
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "CRISPRCasFinder Annotation"
      },
      "add_crisprs": {
        "name": "CRISPR Arrays",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "add_cas_clusters": {
        "name": "Cas Clusters",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "add_cas_genes": {
        "name": "Cas Genes",
        "type": "switch",
        "target": "DialogAdd",
        "default": true
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "CRISPRCasFinder",
        "additionalItems": {
          "BY_TYPE": "By Type"
        },
        "default": "BY_TYPE",
        "help": "Choose or create a new legend for the added features. If By Type is chosen, the feature type will be matched with a legend of the same name. If no legend exists for a type, it will be created."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_crispr_cas_finder/Visualization/index.html",
            "description": "HTML page of results."
          },
          {
            "path": "output_crispr_cas_finder/result.json",
            "description": "JSON file containing main information on detected CRISPRarrays and Cas genes."
          },
          {
            "path": "output_crispr_cas_finder/TSV/CRISPR-Cas_summary.tsv",
            "description": "File containing summary information on both CRISPRs and Cas. This file is also available in Excel format."
          },
          {
            "path": "output_crispr_cas_finder/TSV/Cas_REPORT.tsv",
            "description": "File containing information on detected Cas systems and genes. This file is also available in Excel format."
          },
          {
            "path": "output_crispr_cas_finder/TSV/Crisprs_REPORT.tsv",
            "description": "File containing information on detected CRISPR arrays. This file is also available in Excel format."
          },
          {
            "path": "output_crispr_cas_finder/rawCRISPRs.fna",
            "description": "FASTA file containing sequences of all detected CRISPR arrays."
          },
          {
            "path": "output_crispr_cas_finder/rawCas.fna",
            "description": "FASTA file containing sequences of all detected Cas genes."
          }
        ],
        "helpfulLinks": [
          {
            "name": "CRISPRCasFinder Homepage",
            "url": "https://crisprcas.i2bc.paris-saclay.fr/"
          },
          {
            "name": "CRISPRCasFinder on Github",
            "url": "https://github.com/dcouvin/CRISPRCasFinder"
          }
        ]
      },
      "html": {
        "name": "HTML",
        "type": "dataCard",
        "component": "HTMLDataCard",
        "embed": true,
        "embedType": "html",
        "data": "output_crispr_cas_finder/Visualization/index.html"
      }
    }
  },
  {
    "name": "Prokka",
    "id": "prokka",
    "version": "1.2.0",
    "description": "Annotate the genome sequence with Prokka.",
    "author": "Jason R. Grant",
    "citation": "Seemann T, \"Prokka: Rapid Prokaryotic Genome Annotation\", Bioinformatics, 2014 Jul 15;30(14):2068-9.",
    "link_citation": "https://www.ncbi.nlm.nih.gov/pubmed/24642063",
    "link_software": "https://github.com/tseemann/prokka",
    "software": "Prokka 1.14.6",
    "tool_scheme": "1.0",
    "category": "annotation",
    "worker": "server",
    "queue": "small",
    "threads": 4,
    "docker": "staphb/prokka:1.14.6",
    "components": {
      "DialogStart": {
        "description": "Start a Prokka annotation Job using the map sequence. Once the Job is complete the results can be added to the map as a new Track.",
        "valid": false
      },
      "DialogAdd": {
        "description": "Choose a Track and Legend for these features or use the defaults."
      },
      "ReportCard": true,
      "ProkkaDataCard": true
    },
    "inputs": {
      "contigs": {
        "type": "cgview",
        "data": "contigs",
        "path": "input/"
      },
      "jobName": {
        "type": "textInput",
        "target": "DialogStart",
        "name": "Job Name",
        "default": "Prokka"
      },
      "kingdom": {
        "type": "select",
        "target": "DialogStart",
        "values": {
          "archaea": "Archaea",
          "bacteria": "Bacteria",
          "mitochondria": "Mitochondria",
          "viruses": "Viruses"
        },
        "default": "bacteria",
        "help": "Annotation mode"
      },
      "genus": {
        "type": "select",
        "target": "DialogStart",
        "values": {
          "enterococcus": "Enterococcus",
          "escherichia": "Escherichia",
          "staphylococcus": "Staphylococcus",
          "other": "Other"
        },
        "default": "other",
        "help": "Use genus-specific BLAST databases"
      },
      "genetic_code": {
        "name": "Genetic Code",
        "type": "select",
        "target": "DialogStart",
        "values": {
          "1": "1 - Standard",
          "2": "2 - Vertebrate Mitochondrial",
          "3": "3 - Yeast Mitochondrial",
          "4": "4 - Mold, Protozoan, and Coelenterate Mitochondrial (and also Mycoplasma/Spiroplasma)",
          "5": "5 - Invertebrate Mitochondrial",
          "6": "6 - Ciliate, Dasycladacean and Hexamita Nuclear",
          "9": "9 - Echinoderm and Flatworm Mitochondrial",
          "10": "10 - Euplotid Nuclear",
          "11": "11 - Bacterial and Plant Plastid",
          "12": "12 - Alternative Yeast Nuclear",
          "13": "13 - Ascidian Mitochondrial",
          "14": "14 - Alternative Flatworm Mitochondrial",
          "15": "15 - Blepharisma Nuclear",
          "16": "16 - Chlorophycean Mitochondrial",
          "21": "21 - Trematode Mitochondrial",
          "22": "22 - Scenedesmus obliquus Mitochondrial",
          "23": "23 - Thraustochytrium Mitochondrial"
        },
        "default": "11",
        "help": "The genetic code to use for DNA translation."
      },
      "track": {
        "type": "selectTrack",
        "useFor": "dataTrack",
        "target": "DialogAdd",
        "defaultNewItemName": "Prokka Annotation"
      },
      "legend": {
        "type": "selectLegend",
        "useFor": "dataLegend",
        "name": "Legend",
        "target": "DialogAdd",
        "newItemLabel": "Legend",
        "defaultNewItemName": "Prokka",
        "additionalItems": {
          "BY_TYPE": "By Type"
        },
        "default": "BY_TYPE",
        "help": "Choose or create a new legend for the added features. If By Type is chosen, the feature type will be matched with a legend of the same name. If no legend exists for a type, it will be created."
      }
    },
    "outputs": {
      "results": {
        "type": "mapData",
        "path": "mapData.json"
      },
      "report": {
        "name": "Report",
        "type": "dataCard",
        "component": "ReportCard",
        "data": "mapData.json",
        "featuredFiles": [
          {
            "path": "output_prokka/prokka.tsv",
            "description": "Tab-separated (TSV) file of all the features found by Prokka."
          },
          {
            "path": "output_prokka/prokka.gff",
            "description": "Prokka annotations in GFF3 format. Contains sequences and annotations."
          },
          {
            "path": "output_prokka/prokka.gbk",
            "description": "Genbank file with features. Contains one record for each contig."
          }
        ],
        "helpfulLinks": [
          {
            "name": "Prokka on Github",
            "url": "https://github.com/tseemann/prokka"
          }
        ]
      },
      "prokka": {
        "name": "Prokka Data",
        "type": "dataCard",
        "component": "ProkkaDataCard",
        "data": "output_prokka/prokka.tsv"
      }
    }
  }
];

