import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import './FeaturePane.css';
import { ConnectedSelectLegend } from '../containers/SelectLegend';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextInput from '../presenters/TextInput';
import NumericInput from '../presenters/NumericInput';
import TextElement from '../presenters/TextElement';
import TextEditable from '../presenters/TextEditable';
import ImageButton from '../presenters/ImageButton';
import VisibilityButton from '../presenters/VisibilityButton';
import FavoriteButton from '../presenters/FavoriteButton';
import ButtonGroup from '../presenters/ButtonGroup';
import FeaturesDeleteDialog from './dialogs/FeaturesDeleteDialog';
import FeaturesEditDialog from './dialogs/FeaturesEditDialog';
import ConnectedFeatureSequence from '../containers/FeatureSequence';

import { Select, Option } from '../presenters/Select';
import { VirtualTable, Column } from '../presenters/VirtualTable';
import * as helpers from '../support/Helpers';
import iconGoto from '../images/icon-goto.png';
import '../support/CommonStyles.css';

// Connected
import { connect } from 'react-redux';

class FeaturePane extends Component {

  static propTypes = {
    features: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
    legendItems: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
    featureTypes: PropTypes.array.isRequired,
    // Tracks is not actually used. But if it changes,
    // the features are rendered to update the track list for each feature.
    tracks: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      virtualTableRef: React.createRef(),
      bulkEditIDs: [],
      bulkDeleteIDs: [],
    };
    this.onRowMouseOver = this.onRowMouseOver.bind(this);
    this.onRowMouseOut = this.onRowMouseOut.bind(this);
    this.infoBoxRenderer = this.infoBoxRenderer.bind(this);
    this.nameCellRenderer = this.nameCellRenderer.bind(this);
    this.typeCellRenderer = this.typeCellRenderer.bind(this);
    this.lengthCellRenderer = this.lengthCellRenderer.bind(this);
    this.optionCellRenderer = this.optionCellRenderer.bind(this);
    this.rowClass = this.rowClass.bind(this);
  }

  rowClass(feature) {
    if (feature) {
      return feature.visible ? '' : 'ps-fade';
    }
  }

  searchWithString(string) {
    this.state.virtualTableRef.current.searchWithString(string);
  }

  openInfoFor(id) {
    this.state.virtualTableRef.current.openInfoFor(id);
  }

  // Return Array of cgvIDs for any selected features
  selectedIDs() {
    return Array.from(this.state.virtualTableRef.current.state.selectedIDs);
  }


  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  lengthCellRenderer({ rowData: feature }) {
    const direction = (feature.strand === 1) ? '⇨' : '⇦';
    return (
      <div className='column-value'>
        <div className='column-value-main'>{helpers.commaNumber(feature.length)}</div>
        <div className='column-value-sub'>{direction} {helpers.commaNumber(feature.start)}..{helpers.commaNumber(feature.stop)}</div>
      </div>
    )
  }

  typeCellRenderer({ rowData: feature }) {
    const swatchSize = 8;
    const legendItem = this.props.legendItems.byID[feature.legendID];
    return (
      <div className='column-value'>
        <div className='column-value-main'>{feature.type}</div>
        <div className='column-value-sub'>{this.swatch(legendItem, swatchSize)}{feature.legend}</div>
      </div>
    )
  }

  nameCellRenderer({ rowData: feature }) {
    return (
      <div className='column-value'>
        <div className='column-value-main'>{feature.name}</div>
        <div className='feature-source-tag' title={`Source: ${feature.source}`}>{helpers.featureSourceIDtoSource(feature.source)}</div>
      </div>
    )
  }

  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  optionCellRenderer({ rowData: feature }) {
    // const feature = this.props.features.byID[rowData.cgvID];
    return (
      <ButtonGroup className='option-buttons'>
        <FavoriteButton
          favorite={feature.favorite}
          onClick={ (favorite) => this.onFeatureChange({cgvID: feature.cgvID, value: favorite, attribute: 'favorite'}) }
        />
        <ImageButton
          onClick={ () => this.onClickMoveTo(feature) }
          image={iconGoto}
          title='Move To'
        / >
        <VisibilityButton
          visible={feature.visible}
          onClick={ (visible) => this.onFeatureChange({cgvID: feature.cgvID, value: visible, attribute: 'visible'}) }
        />
      </ButtonGroup>
    )
  }

  onFeatureChange({cgvID, attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    const feature = cgv.objects(cgvID);
    // Do a check, so we only update if the value changes
    if (feature[attribute] !== value) {
      // Extra check for legendItems
      // if (attribute === 'legendItem' && value !== feature.legendItem.cgvID) {
        cgv.updateFeatures(feature, {[attribute]: value});
        // FIXME: this should be done in viewer.updateFeatures!!!
        feature.refresh();
        redraw && cgv.draw();
      // }
    }
  }

  onClickMoveTo(featureRedux) {
    const { cgv, tabsRef: tabs } = this.context;
    tabs.setTabByID('map');
    const feature = cgv.objects(featureRedux.cgvID);
    feature.moveTo(1500);
  }

  onClickViewSource(feature) {
    const tabs = this.context.tabsRef;
    tabs.setTabOrCreateBySource(feature.source);
  }

  swatch(legendItem, size=10, style={}) {
    return ConnectedSelectLegend.renderSwatch(legendItem, size, style);
  }

  renderTrackList(feature) {
    const cgv = this.context.cgv;
    const sidebar = this.context.sidebarRef;
    return (
      feature.tracks.split(', ').map( (trackName, i) => (
        <span
          key={trackName}
          className='clickable'
          onMouseOver={ () => cgv.objects(feature.cgvID).tracks()[i].highlight() }
          onClick={() => sidebar.selectPane('tracks').openInfoFor(cgv.objects(feature.cgvID).tracks()[i].cgvID)}
        >
          {trackName}
        </span>
      ))
      .reduce((prev, curr) => [prev, ', ', curr])
    )
  }
  //    {
  //           feature.tracks.split(', ').map( (t, i) => (
  // /             <span
  // /               key={t}
  // /               onMouseOver={ () => cgv.objects(feature.cgvID).tracks()[i].highlight() }
  // /               onClick={() => }
  // /             >
  // /               {t}
  // /             </span>
  // /           ))
  // /           .reduce((prev, curr) => [prev, ', ', curr])
  // /         }
  // /       </TextElement>

  // Shoe the editable start/stop values for simple features (no locations)
  renderDefaultStartStop(feature) {
    const cgvID = feature.cgvID;
    // FIXME: this should be taken from redux
    // FIXME: this needs to take into account contigs. We'll also need to handle the length change when contigs are hidden.
    const maxLength = cgv.sequence.length;
    return (
      <DataElementGroup>
        <DataElement label='Start' helpPath='help:sidebar:regions:features:start'>
          <NumericInput value={feature.start}
            min={1}
            max={maxLength}
            suffix=' bp'
            onChange={(value) => this.onFeatureChange({cgvID, value, attribute: 'start'})}/>
        </DataElement>
        <DataElement label='Stop' helpPath='help:sidebar:regions:features:stop'>
          <NumericInput value={feature.stop}
            min={1}
            max={maxLength}
            suffix=' bp'
            onChange={(value) => this.onFeatureChange({cgvID, value, attribute: 'stop'})}/>
        </DataElement>
      </DataElementGroup>
    );
  }

  // Locations are not currently editable but we can display them
  renderAllLocations(feature) {
    const locationText = feature.locations.map( l => `${l[0]}..${l[1]}` ).join(', ');
    return (
      <DataElement label='Locations' helpPath='help:sidebar:regions:features:locations'>
        <TextElement>{locationText}</TextElement>
      </DataElement>
    );
  }

  renderLocation(feature) {
    // console.log(feature)
    if (feature.locations?.length > 1) {
      return this.renderAllLocations(feature);
    } else {
      return this.renderDefaultStartStop(feature);
    }
  }

  infoBoxRenderer(feature) {
    // console.log(feature)
    // console.log(feature.tracks)
    const cgv = this.context.cgv;
    const cgvID = feature.cgvID;
    const { featureTypes } = this.props;
    // const legendItems = helpers.itemsAsArray(this.props.legendItems);
    const legendItemID = cgv.objects(feature.cgvID).legend.cgvID;
    // console.log(legendItemID);
    // const tracks = this.context.cgv.objects(cgvID).tracks().map( t => t.name );
    // console.log(feature.tracks)
    // const tracks = feature.tracks;
    // const tracks = feature.tracks.map( t => this.props.tracks.byID[t].name );
    // const feature = this.props.features.byID[rowData.cgvID];

    // FIXME: this should be taken from redux
    // FIXME: this needs to take into account contigs. We'll also need to handle the length change when contigs are hidden.
    const maxLength = cgv.sequence.length;

    const contig = this.context.cgv.sequence.hasMultipleContigs ?
      (
        <Select value={feature.contig}
          showSearch
          onChange={(value) => this.onFeatureChange({cgvID, value, attribute: 'contig'})}>
          { cgv.contigs().map( c => <Option key={c.name}>{c.name}</Option> ) }
        </Select>
      ) :
      ( <TextElement> { cgv.contigs(1).name } </TextElement>);

    const sourceClickable = !['user-defined', 'orfs'].includes(feature.source);

    return (
      <div>
        <DataElement label='Name' helpPath='help:sidebar:regions:features:name'>
          <TextEditable value={feature.name}
            onChange={(value) => this.onFeatureChange({cgvID, value, attribute: 'name'})}/>
        </DataElement>
        <DataElementGroup>
          <DataElement label='Type' helpPath='help:sidebar:regions:features:type'>
            <Select value={feature.type}
              onChange={(value) => this.onFeatureChange({cgvID, value, attribute: 'type'})}>
              { featureTypes.map( f => <Option key={f}>{f}</Option> ) }
            </Select>
          </DataElement>
          <ConnectedSelectLegend
            defaultItemID={legendItemID}
            title='Legend'
            helpPath='help:sidebar:regions:features:legend'
            allowNewItem={false}
            onChange={(value) => this.onFeatureChange({cgvID, value: value.cgvID, attribute: 'legendItem'})}
            help=''
           />
        </DataElementGroup>
        { this.renderLocation(feature) }
        <DataElementGroup>
          <DataElement label='Strand' helpPath='help:sidebar:regions:features:strand'>
            <Select value={`${feature.strand}`}
              onChange={(value) => this.onFeatureChange({cgvID, value, attribute: 'strand'})}>
              <Option value="1">Forward (+)</Option>
              <Option value="-1">Reverse (-)</Option>
            </Select>
          </DataElement>
          <DataElement label='Length' helpPath='help:sidebar:regions:features:length'>
            <TextElement>
              { `${helpers.commaNumber(feature.length)} bp` }
            </TextElement>
          </DataElement>
          <DataElement label='Score' helpPath='help:sidebar:regions:features:score'>
            <NumericInput value={feature.score}
              min={0}
              max={1}
              decimals={3}
              precision={3}
              step={0.001}
              onChange={(value) => this.onFeatureChange({cgvID, value, attribute: 'score'})}/>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Source ID' helpPath='help:sidebar:regions:features:source'>
            <TextElement clickable={sourceClickable} onClick={sourceClickable ? (() => this.onClickViewSource(feature)) : undefined} >
              { feature.source }
            </TextElement>
          </DataElement>
          <DataElement label='Contig' helpPath='help:sidebar:regions:features:contig'>
            { contig }
          </DataElement>
        </DataElementGroup>
        <DataElement label='Tags' helpPath='help:sidebar:regions:features:tags'>
          <Select mode='tags' value={feature.tags}
            placeholder='No tags'
            onChange={(value) => this.onFeatureChange({cgvID, value, attribute: 'tags'})}>
            { cgv.tags().map( s => <Option key={s}>{s}</Option> ) }
          </Select>
        </DataElement>
        <DataElement label='Tracks' helpPath='help:sidebar:regions:features:tracks'>
          <TextElement>{this.renderTrackList(feature)}</TextElement>
        </DataElement>
        <ConnectedFeatureSequence cgvID={feature.cgvID} />
        <div className='action-buttons'>
          <ImageButton imageName='delete' text='Delete Feature' width={115}
          title='Delete Feature' onClick={ () => this.onDeleteFeatures([feature.cgvID]) } />
        </div>
      </div>
    )
  }

  onDeleteFeatures(cgvIDs) {
    this.setState({bulkDeleteIDs: cgvIDs});
  }

  renderDeleteDialog() {
    const { bulkDeleteIDs, virtualTableRef } = this.state;
    if (bulkDeleteIDs.length > 0) {
          // featureTableRef={virtualTableRef.current.tableRef}
      return (
        <FeaturesDeleteDialog
          featureIDs={bulkDeleteIDs}
          featureTableRef={virtualTableRef}
          onClose={() => this.setState({bulkDeleteIDs: []})}
        />
      );
    }
  }

  renderEditDialog() {
    const { bulkEditIDs } = this.state;
    if (bulkEditIDs.length > 0) {
      return (
        <FeaturesEditDialog
          featureIDs={bulkEditIDs}
          featureTypes={this.props.featureTypes}
          onClose={() => this.setState({bulkEditIDs: []})}
        />
      );
    }
  }

  onEditFeatures(cgvIDs) {
    this.setState({bulkEditIDs: cgvIDs});
  }

  onAddFeature() {
    const cgv = this.context.cgv;
    const features = cgv.addFeatures({
      name: 'New Feature',
      start: 1,
      stop: 100,
      contig: cgv.contigs(1),
      source: 'user-defined',
      legend: cgv.legend.items(1) || 'CDS',
      type: cgv.featureTypes(1) || 'CDS',
    });
    this.state.virtualTableRef.current.openInfoFor(features[0].cgvID);
    this.state.virtualTableRef.current.searchWithString(`=${features[0].cgvID}:cgvID`);
    cgv.draw();
    // setTimeout( () => {
    //   this.state.virtualTableRef.current.tableRef.scrollToRow(cgv.features().length);
    // });
  }

  // item = {event, index, rowData}
  onRowMouseOver(e, item) {
    const cgv = this.context.cgv;
    const feature = item && item.cgvID && cgv.objects(item.cgvID);
    if (feature) {
      feature.highlight();
    }
    // console.log(item)
  }

  onRowMouseOut() {
    const cgv = this.context.cgv;
    cgv.clear('background');
    cgv.clear('ui');
  }

  render() {
    const features = helpers.itemsAsArray(this.props.features);
    return (
      <div style={{width: '100%', height: '100%'}} className='FeaturePane'>
        <VirtualTable
          headerHeight={20}
          rowHeight={32}
          infoHeight={500}
          data={features}
          dataName='Features'
          idKey='cgvID'
          infoRenderer={this.infoBoxRenderer}
          selectColumn={true}
          rowClass={this.rowClass}
          ref={this.state.virtualTableRef}
          onRowMouseOver={this.onRowMouseOver}
          onRowMouseOut={this.onRowMouseOut}
          deleteAction={(ids)=>{this.onDeleteFeatures(ids)}}
          editAction={(ids)=>{this.onEditFeatures(ids)}}
          addAction={()=>{this.onAddFeature()}}
        >
          <Column
            label='Name'
            dataKey='name'
            width={120}
            cellRenderer={this.nameCellRenderer}
          />
          <Column
            width={80}
            label='Type'
            dataKey='type'
            cellRenderer={this.typeCellRenderer}
          />
          <Column
            width={120}
            label='Length'
            dataKey='length'
            search='number'
            cellRenderer={this.lengthCellRenderer}
          />
          <Column
            width={100}
            dataKey=''
            search='nosearch'
            style={{justifyContent: 'end'}}
            cellRenderer={this.optionCellRenderer}
          />
          <Column
            label='Start'
            dataKey='start'
            search='number'
            hidden={true}
          />
          <Column
            label='End'
            dataKey='stop'
            search='number'
            hidden={true}
          />
          <Column
            label='Legend'
            dataKey='legend'
            hidden={true}
          />
          <Column
            label='Source'
            dataKey='source'
            hidden={true}
          />
          <Column
            label='Strand'
            dataKey='strand'
            hidden={true}
          />
          <Column
            label='Contig'
            dataKey='contig'
            hidden={true}
          />
          <Column
            label='Tag'
            dataKey='tags'
            hidden={true}
          />
          <Column
            label='cgvID'
            dataKey='cgvID'
            hidden={true}
          />
          <Column
            label='Track'
            dataKey='tracks'
            hidden={true}
          />
          <Column
            label='Score'
            dataKey='score'
            search='number'
            hidden={true}
          />
        </VirtualTable>
        {this.renderEditDialog()}
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

FeaturePane.contextType = CGViewContext;

//Connected
const featurePaneMapStateToProps = (state) => ({ features: state.features, legendItems: state.legendItems, featureTypes: state.stats.featureTypes, tracks: state.tracks });
const ConnectedFeaturePane = connect(featurePaneMapStateToProps, null, null, {forwardRef: true})(FeaturePane);

// export default FeaturePane;
export { FeaturePane, ConnectedFeaturePane};

