import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElement from '../../../presenters/DataElement';
import DataElementGroup from '../../../presenters/DataElementGroup';
import DataElementContainer from '../../../presenters/DataElementContainer';

class DialogAdd extends DefaultDialog {

  renderContents() {
    const { tool, mapData } = this.props;
    const { options } = this.state;

    const regionType = 'misc_feature';
    const features = mapData.features || [];
    const counts = {
      prophage: features.filter( f => f.type === regionType).length,
      genes:    features.filter( f => f.type !== regionType).length,
    };

    let legendFeatures = [...features]
    if (!options.add_prophage) {
      legendFeatures = legendFeatures.filter( f => f.type !== regionType);
    }
    if (!options.add_genes) {
      legendFeatures = legendFeatures.filter( f => f.type === regionType);
    }


    return (
      <div>
        {this.renderHeader()}

        <DataElementContainer label='Features to Add' maxHeight='130px'>
          <DataElementGroup>
            {this.renderInput('add_prophage', {help: `Count: ${counts.prophage}`, align: 'center'})}
            {this.renderInput('add_genes', {help: `Count: ${counts.genes}`, align: 'center'})}
          </DataElementGroup>
          <div style={{textAlign: 'center', width: '100%', fontSize: '0.65rem'}} className='help-text'>Regions will add one feature for each prophage. Genes will add a feature for each gene found within the prophage region.</div>

        </DataElementContainer>

        {this.renderInput('track')}
        {this.renderInput('legend', {featureData: legendFeatures})}

        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;

