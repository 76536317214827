import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import './Linker.css';

// NOTE: I tried using ExternalLink, but the images make it slow.


// This is a faily simple component that takes a string and turns it into a link.
// It can also take a delimiter and extract a value from the string using a RegExp and insert it into the URL.
// It can also take a join string to join the links together.

// FUTURE for more advanced use:
// - Add callback for to format/alter the displayed value
// - Add a callback for the URL creation

Linker.propTypes = {
  className: PropTypes.string,
  value:  PropTypes.string,
  url: PropTypes.string,
  delimiter: PropTypes.string,
  extractRegex: PropTypes.object,
  join: PropTypes.string,
  displayFunc: PropTypes.func,
}

Linker.defaultProps = {
  displayFunc: (v) => v,
}

function Linker(props) {
  const {
    className,
    value,
    url,
    delimiter,
    extractRegex,
    join,
    displayFunc,
  } = props;

  const elementClass = classNames(
    'Linker', className
  );

  // Split values using delimiter
  let splits = [value];
  if (delimiter) {
    splits = value.split(delimiter);
  }
  // Trim values of white space
  splits = splits.map( v => v.trim() );

  // Extract the value using a RegExp
  let values = [...splits];
  if (extractRegex) {
    values = splits.map( (v) => {
      const result = v.match(extractRegex);
      let value = result ? result[1] : null;
      return value;
    });
  }

  // Insert values into the URL
  let urls = [...splits];
  if (url) {
    urls = values.map( v => url.replace('{VALUE}', v) );
  }

  // Join the links together using join or delimiter
  const links = splits
    .map( (s,i) => (extractRegex && values[i] === null) ? displayFunc(s) : <a target='_blank' title='Open in new Tab or Window' key={s} href={urls[i]}>{displayFunc(s)}</a>)
    .reduce( (prev, curr) => [prev, (join || delimiter), curr] );

  return (
    <div className={elementClass}>
      {links}
    </div>
  )
}

export default Linker;

