import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './JobSummary.css';
import { CGViewContext } from '../app/CGViewContext';
import ServerAPI from '../models/ServerAPI';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextElement from '../presenters/TextElement';
import TextEditable from '../presenters/TextEditable';
import FavoriteButton from '../presenters/FavoriteButton';
import {ConnectedAddJobResultsButton} from '../containers/AddJobResultsButton';
import iconChevron from '../images/icon-chevron.png';
import Job from '../models/Job';
import * as helpers from '../support/Helpers';

// Connected
import { connect } from 'react-redux';

// TODO:
// Actions: Repeat Job
// New Elements: Tool (with link),
//
// Feature Source ID:
// - Magnifying glass icon beside Feature Source ID
// - Not a link until there are features with this source. Is that possible?

class JobSummary extends React.Component {

  static propTypes = {
    job: PropTypes.object,
    summaryOpen: PropTypes.bool,
  }

  static defaultProps = {
    summaryOpen: true,
    // detailsInitialTabKey: '1',
  }

  constructor(props) {
    super(props);

    this.state = {
      open: props.summaryOpen,
    }

    this.onClickHeader = this.onClickHeader.bind(this);
    this.onChangeJob = this.onChangeJob.bind(this);
  }

  // Update Tab title if the job name changes
  componentDidUpdate(prevProps) {
    if (prevProps.job.name !== this.props.job.name) {
      const jobID = `job-${this.props.job.id}`;
      this.context.tabsRef.renameByID(jobID, this.props.job.name);
    }
  }

  onClickHeader() {
    this.setState( (state) => ({open: !state.open}));
  }

  onChangeJob({attribute, value}) {
    const { job } = this.props;
    const Server = new ServerAPI();
    Server.patchJob(job.id, {[attribute]: value});
  }

  sourceClicked(sourceID) {
    const sidebar = this.context.sidebarRef;
    const featurePane = sidebar.selectPane('features');
    featurePane.searchWithString(`${sourceID}:source`);
  }

  // Error: At top of page only if there are any errors
  // -----
  // Summary:
  // Actions: Favorite, Add to Map, Repeat Job, Delete
  // ID, Tool (with link), Runtime

  render() {
    const { job } = this.props;
    const { open } = this.state;

    if (!job) { return null; }

    const jobSummaryClass = classNames(
      'JobSummary',
      {open: this.state.open}
    );

    let sourceID;
    if (/-features$/.test(job.cgviewSourceId)) {
      sourceID = '*-features';
    } else {
      sourceID = `${job.cgviewSourceId}.*`;
    }

    const daysRemainingElement = job.daysRemaining &&
       <DataElement label='Days Left' helpPath='help:jobs:days_left'><TextElement>{job.daysRemaining}</TextElement></DataElement>

    return (
      <div className={jobSummaryClass}>
        <div className='header' onClick={this.onClickHeader}>
          <div className='toggle'>
            <img src={iconChevron} width='15' height='9' alt='open/close' />
          </div>
          <div className='job-title'>
            <div>Job: {job.name}</div>
            <div>
              <div className='job-status'>{job.status}</div>
              <FavoriteButton
                favorite={job.favorite}
                type='plain'
                onClick={ (value) => this.onChangeJob({attribute: 'favorite', value}) }
              />
              <ConnectedAddJobResultsButton  id={job.id} type='plain' />
            </div>
          </div>
        </div>
        <div className='summary-content'>
          <div className='group-name-id'>
            <DataElement label='Name' helpPath='help:jobs:name'>
              <TextEditable value={job.name} onChange={ (value) => this.onChangeJob({attribute: 'name', value})}/>
            </DataElement>
            <DataElement label='Size' helpPath='help:jobs:size'>
              <TextElement>{ (job.size ? helpers.formatBytes(job.size) : '0 B') }</TextElement>
            </DataElement>
            <DataElement label='ID' helpPath='help:jobs:id'>
              <TextElement>{job.id}</TextElement>
            </DataElement>
          </div>
          <DataElementGroup>
            <DataElement label='Created' helpPath='help:jobs:created'>
              <TextElement>{helpers.friendlyDateTime(job.createdAt)}</TextElement>
            </DataElement>
            <DataElement label='Run Time' helpPath='help:jobs:run_time'>
              <TextElement>{job.displayRuntime}</TextElement>
            </DataElement>
            <DataElement label='Feature Source ID' helpPath='help:jobs:source_id'>
              <TextElement clickable onClick={() => this.sourceClicked(sourceID)} >
                {sourceID}
              </TextElement>
            </DataElement>
          </DataElementGroup>
          <DataElementGroup>
            <DataElement label='Start Time' helpPath='help:jobs:started'>
              <TextElement>{helpers.friendlyDateTime(job.startedAt)}</TextElement>
            </DataElement>
            <DataElement label='End Time' helpPath='help:jobs:ended'>
              <TextElement>{helpers.friendlyDateTime(job.endedAt)}</TextElement>
            </DataElement>
            {daysRemainingElement}
          </DataElementGroup>
          <DataElement label='Inputs' helpPath='help:jobs:inputs'>
            <TextElement className='job-inputs'>{Job.inputsAsHTML(job)}</TextElement>
          </DataElement>
        </div>
      </div>
    );
  }
}

JobSummary.contextType = CGViewContext;

export default JobSummary;
