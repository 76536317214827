import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';
import FeaturesByTypeTable from '../../../cards/FeaturesByTypeTable';
// import * as helpers from '../../../support/Helpers';

class ReportCard extends ReportCardBase {

  get extractType() {
    return 'json';
  }

  renderData(mapData) {
    // console.log(mapData)

    const regionType = 'misc_feature';
    const regionCount = mapData?.features?.filter(f => f.type === regionType).length || 0;
    const featureCount = mapData?.features?.filter(f => f.type !== regionType).length || 0;
    const featureData = [
      { name: 'Prophage Regions', count: regionCount, dataCard: 'summary' },
      { name: 'Prophage Genes', count: featureCount, dataCard: 'details' },
      { name: 'Total Features', count: regionCount + featureCount },
    ];

    return (
      <div className='report-card'>
        { this.renderReportHeader() }
        { this.renderReportFailed() }
        { this.renderMapResultsListTable(featureData) }
        { this.renderFeaturedFilesTable() }
        { this.renderHelpfulLinks() }
        { this.renderCitation() }
      </div>
    );
  }

}

export default ReportCard;

