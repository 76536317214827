// NOTES:
// - A DataElement is a single element (e.g text input, select, swtich, etc) with a label and optional help
// - A DataElementGroup is a row of of DataElements, filled to take the available space (by width)
// - A DataElementContainer contains one or more DataElements and/or DataElementGroups

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HelpLink from './HelpLink'
import './DataElement.css';
import './DefaultSettingsManager.css';

DataElement.propTypes = {
  className: PropTypes.string,
  label:     PropTypes.node,
  labelRight: PropTypes.node,
  align:     PropTypes.string,
  // children:  PropTypes.element.isRequired,
  children:  PropTypes.element,
  help:      PropTypes.string,
  helpPath:  PropTypes.string,
  disabled:  PropTypes.bool,
  closable:  PropTypes.bool,
  closed:    PropTypes.bool,
  nonDefault: PropTypes.bool,
}

DataElement.defaultProps = {
  disabled: false,
  align: 'left',
  closable: false,
  closed: false,
  nonDefault: false,
}

function DataElement(props) {
  const {
    className,
    label,
    labelRight,
    align,
    children,
    help,
    helpPath,
    // disabled=false,
    closable,
    closed,
    nonDefault,
  } = props;

  const [isClosed, setIsClosed] = useState(closed);

  const elementClass = classNames(
    'DataElement', className,
    {closable},
    {closed: isClosed},
  );

  const labelStyle = {
    textAlign: align
  }

  const childClass = classNames(
    'data-child',
  );

  // Appears below element
  const renderHelp = help ? <div style={labelStyle} className='help-text' dangerouslySetInnerHTML={{__html: help}}></div> : '';

  // Appears beside element label
  const renderHelpLink = helpPath ? <HelpLink  helpPath={helpPath} /> : '';
  // const renderHelpLink = '';

  // const childStyle = {
  //   justifyContent: (align === 'left') ? 'flex-start' : align
  // }
  const nonDefaultDot = nonDefault && <span className='label-non-default-dot' title='Non Default'></span> || '';

  return (
    <div className={elementClass}>
      <div className='label'
        style={labelStyle}
        onClick={() => setIsClosed(!isClosed)}
      >
        <div className='space-between'>
          <div>
            {nonDefaultDot}
            {label}
            {renderHelpLink}
          </div>
          {labelRight}
        </div>
      </div>
      <div className={childClass}>
        {children}
      </div>
      {renderHelp}
    </div>
  )
}

export default DataElement;


