import React from 'react';
import Inputs from './Inputs';
import Outputs from './Outputs';
import Component from './Component';
import ToolComponents from '../constants/ToolComponents';
import ExternalLink from '../presenters/ExternalLink';
import ServerAPI from '../models/ServerAPI';
// import Message from '../presenters/Message';
import * as CGView from '../../CGView.js';

class Tool {

  constructor(json) {
    const keys = [
      'name','short_name', 'id', 'description', 'author', 'citation', 'software', 'link_citation', 'link_software',
      'version', 'category', 'worker', 'hidden', 'outputs', 'enabled', 'tags'
    ];
    for (const key of keys) {
      this[key] = json[key];
    }
    // Add Components
    this.components = {};
    if (json.components) {
      for (const key of Object.keys(json.components)) {
        this.components[key] = new Component(this, key, json.components[key]);
      }
    }
    this.add = ToolComponents[this.id]['add'] || this.defaultAdd;

    // Add Inputs
    // We only add tool id for accessing DefaultSettingsManager LocalStorage
    this.inputs = new Inputs(json.inputs, this.id);

    // Add Outputs
    this.outputs = new Outputs(this, json.outputs);
  }

  get tags() {
    return this._tags;
  }

  set tags(value) {
    this._tags = (value == undefined || value === '') ? [] : [value].flat();
  }

  get deletableJobs() {
    return this.id !== 'cgview_builder';
  }

  tagsInclude(value) {
    return this.tags.map(t => t.toLowerCase()).includes(value.toLowerCase());
  }

  inputsForTarget(target) {
    return this.inputs.list.filter( i => i.target === target);
  }

  fileInputs() {
    return this.inputs.list.filter( i => i.type === 'file');
  }

  sequenceSelectorInputs() {
    return this.inputs.list.filter( i => i.type === 'sequenceSelector');
  }

  dataCardOutputs() {
    return this.outputs.list.filter( i => i.type === 'dataCard');
  }

  mapDataOutputPath() {
    const mapData = this.outputs.list.filter( i => i.type === 'mapData')[0];
    let newPath = 'UNKNOWN';
    if (mapData) {
      // NOTE: this could be extracted if need elsewhere
      const path = mapData.path;
      if (path) {
        newPath = (path.match(/\/$/)) ? `${path}${mapData.id}` : path;
      } else {
        newPath = mapData.id;
      }
    }
    return newPath
  }

  dialog(id, onClose, mapData, job) {
    const ReactComponent = this.components[id].react;
    return <ReactComponent onClose={onClose} mapData={mapData} tool={this} job={job} componentID={id} visible={true} />
  }

  openDialog(onClose, mapData) {
    const dialogType = (this.worker === 'server') ? 'DialogStart' : 'DialogAdd';
    return this.dialog(dialogType, onClose, mapData);
  }

  startDialog(onClose) {
    return this.dialog('DialogStart', onClose);
  }

  addDialog(onClose, mapData, job) {
    return this.dialog('DialogAdd', onClose, mapData, job);
  }

  getCGViewData(cgv) {
    const data = {};
    for (const id of this.inputs.ids) {
      const input = this.inputs.get(id);
      // console.log(input)
      if (input.type === 'cgview') {
        switch (input.data) {
          case 'contigs':
            // Returns the sequence as a multifasta string
            data[id] = cgv.sequence.asFasta();
            break;
          case 'cgview':
            data[id] = JSON.stringify(cgv.io.toJSON());
            break;
        }
      }
    }
    // console.log(data)
    return data;
  }

  // Returns the Name for the job provided by the special jobName input.
  // If jobName is not available the tool name is returned.
  // Options are the inputs and their values from the dialogs.
  jobName(options={}) {
    return options.jobName || this.name;
  }

  renderDescription() {
    return <div>{this.description}</div>
  }

  renderSoftware() {
    const software = this.link_software ? <ExternalLink name={this.software} link={this.link_software} size={12} /> : this.software;
    return <div>{software}</div>
  }

  renderToolVersionLink() {
    const toolLink = `/tools/${this.id}`;
    return <div><ExternalLink name={this.version} link={toolLink} size={12} /></div>
  }

  renderCitation() {
    const citation = this.link_citation ? <ExternalLink name={this.citation} link={this.link_citation} size={12} /> : this.citation;
    return <div>{citation}</div>
  }

  renderInputs(onChange) {
    return this.inputs.render(onChange);
  }

  renderInputsForTarget(target, onChange, currentValues) {
    return this.inputs.renderForTarget(target, onChange, currentValues);
  }

  defaultOptions() {
    return this.inputs.defaultOptions();
  }

  // legendData will be an object with the following attributes
  // - cgvID: 'cgv-id-#', 'NEW', 'BY-TYPE', 'SAME', or an additionalItem name
  // - name: name for NEW legend
  // - colorSwatch: color for NEW legend
  // Returns either:
  // - New LegendItem
  // - Existing LegendItem
  // - A string: e.g. 'BY-TYPE', 'SAME'
  getLegend(cgv, legendData) {
    let legend;
    if (legendData) {
      if (legendData.cgvID === 'NEW') {
        legend = cgv.legend.addItems({
          name: legendData.name,
          swatchColor: legendData.swatchColor,
        })[0];
      // This allows the common opton of setting the legend By Type
      } else if (legendData.cgvID === 'BY_TYPE') {
        legend = 'BY_TYPE';
      // NOTE: this could cause an issue if selectLegend has additionalItems (beyond BY_TYPE)
      } else {
        legend = cgv.objects(legendData.cgvID);
      }
      if (!legend) {
        // Return the id (e.g. for an additional item) if no legend was found or created
        legend = legendData.cgvID;
      }
    }
    return legend;
  }

  getDataLegend(cgv, inputs, useFor) {
    const dataLegendInput = this.inputs.withUseFor(useFor);
    const optionsForLegend = inputs[dataLegendInput && dataLegendInput.id];
    return this.getLegend(cgv, optionsForLegend);
  }


  // cgv: CGView object
  // mapData: object containing data to add
  // inputs: object of input values provided by the dialog
  // mapData structure: {
  //   sourcePrefix: '...',  # Prefix to use for source. Otherwise, the tool id will be used
  //   features: [{...}],    # Array of feature data to be passed to addFeatures
  //   tracks: [{...}],      # Array of feature data to be passed to addTracks
  // }
  addToMap({cgv, mapData, inputs, jobSourceID, jobID}) {
    if (!mapData) { return; }
    // console.log(mapData)

    // DefaultSource will be used for adding feature and linking to a provided dataTrack.
    const sourcePrefix = jobSourceID || mapData.sourcePrefix || `${this.id}-`;
    // console.log(sourcePrefix)
    const defaultSource = CGView.utils.uniqueId(sourcePrefix, 1, cgv.sources());

    // Send action to server if it's from a job
    if (jobID) {
      const Server = new ServerAPI();
      // TODO: Not catching any errors. Maybe we should put addToMap in a try-catch loop
      Server.post(Server.URL.logAddToMap, {
        job_id: jobID,
        inputs: {...inputs, sourceID: defaultSource},
      });
    }

    // Data Legend: If present will be used for all features
    const dataLegend = this.getDataLegend(cgv, inputs, 'dataLegend');

    // Add legends if provided and they don't already exist
    if (dataLegend === 'BY_TYPE') {
      this.addProvidedByTypeLegends({cgv, mapData});
    }
    console.log(dataLegend)

    // Features
    const features = mapData.features;
    if (features) {
      // Update source and legend (if not present)
      for (const feature of features) {
        // Override source value so it matches with tract source (below)
        // This may change with future tools
        feature.source = defaultSource;
        // BY_TYPE - special case - DEPRECATED - use 'by:type' instead
        if (dataLegend === 'BY_TYPE') {
          feature.legend = feature.type;
        } else if (dataLegend?.startsWith?.('by:')) {
          const keys = dataLegend.split(':').slice(1); // Remove 'by:' and get key parts
          feature.legend = keys.reduce((o, k) => (o && o[k] !== undefined ? o[k] : undefined), feature);
          // const key = dataLegend.split(':')[1];
          // feature.legend = feature[key];
        // An existing or new legend item has been selected
        } else if (dataLegend && (dataLegend.toString() === 'LegendItem')) {
          feature.legend = dataLegend;
        // Sets the legend to the dataLegend value if the feature has no legend
        } else if (dataLegend && !feature.legend) {
          feature.legend = dataLegend;
        }
        // Else use the legend already assigned to feature
      }
      cgv.addFeatures(features);
    }

    // Plots
    const plots = mapData.plots;
    if (plots) {
      // Check for positive and negative legends
      const dataLegendPositive = this.getDataLegend(cgv, inputs, 'dataLegendPositive');
      const dataLegendNegative = this.getDataLegend(cgv, inputs, 'dataLegendNegative');
      // Update source and legend (if not present)
      for (const plot of plots) {
        // Override source value so it matches with tract source (below)
        // This may change with future tools
        // plot.source = defaultSource;
        plot.legendPositive = dataLegendPositive || dataLegend;
        if (dataLegendNegative && dataLegendNegative !== 'SAME') {
          plot.legendNegative = dataLegendNegative;
        } else {
          plot.legendNegative = dataLegendPositive || dataLegend;
        }
      }
      cgv.addPlots(plots);
    }

    // Tracks
    const tracks = mapData.tracks;
    if (tracks) {
      cgv.addTracks(tracks);
    }

    // Data Track: If present will be used for all features
    // NOTE: Not used for plots as plots will never be added to an existing track.
    //       Instead plots will always be added to a new track so only a track
    //       name is required
    const dataTrackInput = this.inputs.withUseFor('dataTrack');
    const optionsForTrack = inputs[dataTrackInput && dataTrackInput.id];
    // console.log(dataTrackInput)
    // console.log(optionsForTrack)
    if (optionsForTrack) {
      // console.log(optionsForTrack)
      if (optionsForTrack.cgvID === 'NEW') {
        cgv.addTracks({
          name: optionsForTrack.name,
          dataType: 'feature',
          dataMethod: 'source',
          dataKeys: defaultSource,
          separateFeaturesBy: dataTrackInput.separateFeaturesBy,
          drawOrder: dataTrackInput.drawOrder,
        });
      } else {
        const track = cgv.objects(optionsForTrack.cgvID);
        // console.log(track)
        if (track.toString() === 'Track') {
          track.update({dataKeys: [...track.dataKeys, defaultSource]});
        }
      }
    }

    // Captions
    const captions = mapData.captions;
    if (captions) {
      cgv.addCaptions(captions);
    }

    cgv.draw();
  }

  // If the BY_TYPE was selcted for legend and the job mapData has legend data
  // add the legends if they don't already exist and a feature with that type exists.
  addProvidedByTypeLegends({cgv, mapData}) {
    // Check for BY_TYPE occurs in addToMap
    let legends = mapData.legends;
    if (legends) {
      let types = mapData.features?.map(f => f.type) || [];
      types =  Array.from(new Set(types));
      legends = legends.filter(l => types.includes(l.name));
      const currentLegends = cgv.legend.items().map(l => l.name);
      legends = legends.filter(l => !currentLegends.includes(l.name));
      cgv.legend.addItems(legends);
    }
  }

  // Called after the tool DialogAdd is confirmed (OK is clicked)
  handleAddConfirmed({cgv, mapData, inputs, jobSourceID, jobID}) {
    let response = {};
    try {
      response = this.add({tool: this, cgv, inputs, mapData});
      // console.log(response)
      if (response.ok) {
        // console.log(response)
        this.addToMap({cgv, mapData: response.mapData, inputs, jobSourceID, jobID});
      }
    } catch (error) {
      response.error = `${error.name}: ${error.message}`;
      response.ok = false;
      // console.log(error)
    }
    // Message.close();
    return response;
  }

  // If no add.js is found this will be used as the default
  defaultAdd({tool, cgv, mapData, inputs}) {
    return {ok: true, mapData};
  }

}

export default Tool;

